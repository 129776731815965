import { isLocal } from "../utils/configHelper";
export class ConfigOptions {
  // Okta settings
  public static readonly oktaClientId = process.env.REACT_APP_OKTA_CLIENT_ID;
  public static readonly oktaAuthServerId =
    process.env.REACT_APP_OKTA_AUTH_SERVER_ID;
  public static readonly oktaIssuer = process.env.REACT_APP_OKTA_ISSUER;
  public static readonly oktaRedirectUri =
    process.env.REACT_APP_OKTA_REDIRECT_URL;
  public static readonly oktaLoginUrl = process.env.REACT_APP_OKTA_SIGNIN_URL;

  public static readonly mddApiUrl = `${
    isLocal ? "https://" : window.location.protocol
  }${
    isLocal
      ? process.env.REACT_APP_BASE_API_URL || "undefined"
      : `//${window.location.hostname}/endsight/api`
  }`;

  public static readonly studyApiUrl = `https://${process.env.REACT_APP_STUDY_API_URL}`;

  public static readonly root = `${window.location.protocol}//${window.location.hostname}`;

  public static readonly studyApiPath: string = `/Study`;
  public static readonly sponsorApiPath: string = `/Sponsor`;
  public static readonly lookupApiPath: string = `/Lookup`;
  public static readonly siteApiPath: string = `/Site`;
  public static readonly scaleApiPath: string = `/Scale`;
  public static readonly personnelApiPath: string = `/Personnel`;

  public static readonly searchStudyApiPath: string = `${ConfigOptions.studyApiPath}/Search`;
  public static readonly clinicalDataStudyApiPath: string = `${ConfigOptions.studyApiPath}/ClinicalData`;
  public static readonly getStudyProtocolsApiPath: string = `${ConfigOptions.studyApiPath}/Protocols`;
  public static readonly putStudyProtocolApiPath: string = `${ConfigOptions.studyApiPath}/Protocols`;
  public static readonly poststudyProtocolApiPath: string = `${ConfigOptions.studyApiPath}/Protocol`;
  public static readonly studyQualificationsApiPath = (studyId: string) =>
    `${ConfigOptions.studyApiPath}/${studyId}/Qualification`;
  public static readonly scoreApiPath: string = `/score`;

  public static readonly allSponsorApiPath: string = `${ConfigOptions.sponsorApiPath}/all`;
  public static readonly searchSponsorApiPath: string = `${ConfigOptions.sponsorApiPath}/search`;
  public static readonly getSponsorProgramsApiPath: string = `${ConfigOptions.sponsorApiPath}/GetPrograms`;
  public static readonly sponsorProgramApiPath: string = `${ConfigOptions.sponsorApiPath}/Program`;

  public static readonly getSiteCommentsApiPath: string = `${ConfigOptions.siteApiPath}/Comments`;
  public static readonly searchSiteApiPath: string = `${ConfigOptions.siteApiPath}/Search`;
  public static readonly siteCommentApiPath: string = `${ConfigOptions.siteApiPath}/Comment`;

  public static readonly searchStudySitesApiPath: string = `${ConfigOptions.studyApiPath}/Sites`;
  public static readonly studySiteApiPath: string = `${ConfigOptions.studyApiPath}/Site`;
  public static readonly studyScaleApiPath: string = `${ConfigOptions.studyApiPath}/Scale`;

  public static readonly managedPersonnelPath: string = `${ConfigOptions.personnelApiPath}/managed`;

  public static readonly THERAPEUTICAREAS: string = "therapeuticAreas";
  public static readonly INDICATIONS: string = "indications";
  public static readonly DEMOGRAPHICS: string = "demographics";
  public static readonly SPECIALITIES: string = "specialities";
  public static readonly SPONSORS: string = "sponsors";
  public static readonly COUNTRIES: string = "countries";
  public static readonly STATES: string = "states";
  public static readonly PROTOCOLS: string = "protocols";
  public static readonly SITES: string = "sites";
  public static readonly ROLES: string = "roles";
  public static readonly MASTER_SCALES: string = "master";
  public static readonly QUALIFICATION_TYPE: string = "qualificationtypes";
  public static readonly TIMEZONES: string = "timezones";
  public static readonly US_COUNTRY_CODE: string = "US";
  public static readonly EMPTY_GUID: string =
    "00000000-0000-0000-0000-000000000000";
  public static readonly REQUIRED_HELPER_TEXT: string =
    "This field is required";
  // This should be an env variable but we have to get this working for docker containers.
  public static readonly CHARON_ENDSIGHT_APP_NAME =
    process.env.REACT_APP_CHARON_ENDSIGHT_APP_NAME || "endSight";
  public static readonly CHARON_ENDSIGHT_INTEGRATIONS_APP_NAME =
    process.env.REACT_APP_CHARON_ENDSIGHT_APP_NAME || "DoceboLMS";
}
