import * as React from "react";
import { MddDialog, MddDialogButtons } from "../../../components/common";

interface MddResConfigPublishProps {
  open: boolean;
  onClose: (isSave: boolean) => void;
}

export const MddAddEditSiteConfirmDialog = ({
  open,
  onClose
}: MddResConfigPublishProps) => {
  return (
    <MddDialog
      showCloseButton={true}
      onCloseClick={() => onClose(false)}
      dialogProps={{
        id: "divConfirmSaveDialog",
        open,
        keepMounted: false,
        maxWidth: "md"
      }}
      dialogActionProps={{
        id: "divConfirmSaveDialogActions",
        className: "mdd-study-scores--lock-button"
      }}
      dialogContentProps={{
        id: "divConfirmSaveDialogContent"
      }}
      dialogTitleProps={{
        id: "divConfirmSaveDialogTitle",
        title: "Confirm Timezone Change"
      }}
      dialogContent={
        <span>
          <div>
            Are you sure you want to proceed with the selected time zone?
          </div>
          <div className="mdd-add-edit-site--dialog-spacing">
            The time zone selected will determine all on-site timestamps for
            reporting and other purposes. This change will apply to ALL studies
            associated with this site.
          </div>
        </span>
      }
      dialogActions={
        <MddDialogButtons
          saveButtonText="Confirm"
          closeButtonText="Cancel"
          saveButtonProps={{
            id: "btnConfirmYes",
            onClick: () => {
              onClose(true);
            }
          }}
          closeButtonProps={{
            id: "btnConfirmNo",
            onClick: () => onClose(false)
          }}
        />
      }
    />
  );
};
