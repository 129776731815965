import { ConfigOptions } from "../config";
import { eLookupActionTypes } from "../store";
import { store, callApi } from "../utils";
import { eApiNames, eHTTPMethods } from "../constants";
import { IIdNamePair } from "../model";

export abstract class LookupService {
  public static async getDemographics() {
    const demographics = await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.lookupApiPath}/${ConfigOptions.DEMOGRAPHICS}`
    );
    store.dispatch({
      type: eLookupActionTypes.SET_LOOKUP,
      payload: { demographics }
    });
  }

  public static async getIndications() {
    const indications = await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.lookupApiPath}/${ConfigOptions.INDICATIONS}`
    );
    store.dispatch({
      type: eLookupActionTypes.SET_LOOKUP,
      payload: { indications }
    });
  }

  public static getDemographicTypes(): Promise<{
    value: IIdNamePair[];
  }> {
    return callApi(
      eHTTPMethods.GET,
      "/lookups/demographics",
      undefined,
      undefined,
      eApiNames.STUDY_API
    );
  }

  public static getIndicationTypes(): Promise<{
    value: IIdNamePair[];
  }> {
    return callApi(
      eHTTPMethods.GET,
      "/lookups/indications",
      undefined,
      undefined,
      eApiNames.STUDY_API
    );
  }

  public static async getTherapeuticAreas() {
    const therapeuticAreas = await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.lookupApiPath}/${ConfigOptions.THERAPEUTICAREAS}`
    );
    store.dispatch({
      type: eLookupActionTypes.SET_LOOKUP,
      payload: { therapeuticAreas }
    });
  }

  public static async getSpecialities() {
    const specialties = await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.lookupApiPath}/${ConfigOptions.SPECIALITIES}`
    );
    store.dispatch({
      type: eLookupActionTypes.SET_LOOKUP,
      payload: { specialties }
    });
  }

  public static async getCountries() {
    const countries = await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.lookupApiPath}/${ConfigOptions.COUNTRIES}`
    );
    store.dispatch({
      type: eLookupActionTypes.SET_LOOKUP,
      payload: { countries }
    });
  }

  public static async getSponsors() {
    return await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.lookupApiPath}/${ConfigOptions.SPONSORS}`
    );
  }

  public static async getStates() {
    return await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.lookupApiPath}/${ConfigOptions.STATES}`
    );
  }

  public static async getSites() {
    return await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.lookupApiPath}/${ConfigOptions.SITES}`
    );
  }

  public static async getRoles() {
    return await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.lookupApiPath}/${ConfigOptions.ROLES}`
    );
  }

  public static async getStudyProtocols(studyId: string) {
    return await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.lookupApiPath}/${ConfigOptions.PROTOCOLS}/${studyId}`
    );
  }

  public static async getQualificationTypes() {
    const qualificationTypes = await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.lookupApiPath}/${ConfigOptions.QUALIFICATION_TYPE}`
    );
    store.dispatch({
      type: eLookupActionTypes.SET_LOOKUP,
      payload: { qualificationTypes }
    });
  }

  public static async getTimezones() {
    return await callApi(
      eHTTPMethods.GET,
      `${ConfigOptions.lookupApiPath}/${ConfigOptions.TIMEZONES}`
    );
  }
}
