import * as React from "react";
import { pick, trim } from "lodash";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AddCircle from "@material-ui/icons/AddCircle";
import {
  BktDataTable,
  IBktDataTableData,
  SagaService,
  IAction
} from "front-end-lib/core";

import { studyDatatableHeaders, studyDataTableProps } from "../../../config";
import {
  BktBreadcrumb,
  withMddSponsor,
  withMddLookups,
  MddForm
} from "../../../components";
import { IStudy, ILookup, IMddSponsorProps, Study } from "../../../model";
import { eStudySaga } from "../../../sagas";
import { eStudyActionTypes } from "../../../store";
import { history, store } from "../../../utils";

export interface IBktDataTableStudy extends IStudy {
  key: string;
}

interface IBktShowSponsorStudiesProps extends IMddSponsorProps {
  demographics: ILookup[];
  indications: ILookup[];
  therapeuticAreas: ILookup[];
}

const _BktShowSponsorStudies = (props: IBktShowSponsorStudiesProps) => {
  const [studies, setStudies] = React.useState(undefined);
  const {
    match: {
      params: { id }
    },
    demographics,
    indications,
    therapeuticAreas,
    sponsor,
    loadingKeys
  } = props;
  let filterVals = React.useRef(new Map());

  React.useEffect(() => {
    const removeFetchStudies = SagaService.subscribeToSaga(
      eStudySaga.FETCH_STUDIES,
      (action: IAction) => {
        if (action.type === eStudySaga.FETCH_STUDIES_SUCCESS) {
          setStudies(action.payload);
        }
      }
    );

    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_STUDIES,
      payload: { sponsorId: id }
    });

    return () => removeFetchStudies();
    // eslint-disable-next-line
  }, []);

  const handleAddNew = () => {
    store.dispatch({
      type: eStudyActionTypes.SET_STUDY,
      payload: Object.assign(new Study(), { sponsorId: id })
    });
    history.push(`/study/new/${id}`);
  };

  const handleChangeFilter = async (event: any) => {
    const { name } = event.target;
    const value = event.target.value ? trim(event.target.value) : "";
    const searchStudy = {};

    filterVals.current.set("sponsorId", id);

    if (
      filterVals.current.has(name) &&
      filterVals.current.get(name) === value
    ) {
      return;
    }

    filterVals.current.set(name, value);

    filterVals.current.forEach((value, key) => {
      Object.assign(searchStudy, { [key]: value });
    });

    SagaService.dispatchSaga({
      type: eStudySaga.FETCH_STUDIES,
      payload: searchStudy
    });
  };

  const getFormattedStudies = () => {
    const dataProps = [
      "StudyName",
      "InternalStudyName",
      "Protocol",
      "TherapeuticArea",
      "Indication",
      "Demographic"
    ];
    return studies
      ? (studies || []).map((st: IBktDataTableStudy) => {
          return Object.assign(pick(st, dataProps) as IBktDataTableStudy, {
            key: st.StudyId
          });
        })
      : [];
  };

  return (
    <div style={{ height: "100%" }}>
      <div>
        <BktBreadcrumb
          rootText="All Sponsors"
          rootUrl="/sponsor"
          currentLocation={sponsor.SponsorName}
        />
      </div>
      <MddForm>
        <div className="mdd-grid--with-menu mdd-grid">
          <Paper id="headerContainer">
            <Typography id="headerTitle">Studies</Typography>
            <Button
              id="addStudyButton"
              variant="contained"
              size={"small"}
              onClick={handleAddNew}
            >
              <AddCircle id="addCircleIcon" />
              Add New Study
            </Button>
          </Paper>
          <Paper id="bodyContainer">
            <div>
              <BktDataTable
                data={getFormattedStudies()}
                defaultSortProp="StudyName"
                loading={loadingKeys.some(
                  (lk) => lk === `undefined_${eStudySaga.FETCH_STUDIES}`
                )}
                columnProps={studyDatatableHeaders(
                  handleChangeFilter,
                  filterVals.current as Map<string, any>,
                  therapeuticAreas,
                  indications,
                  demographics
                ).filter((dth) => ["sponsor", "scales"].indexOf(dth.key) < 0)}
                rowActions={[
                  {
                    isPrimary: true,
                    action: (
                      data: IBktDataTableData,
                      event: React.MouseEvent<HTMLDivElement>
                    ) => history.push(`/study/${data.key}`)
                  }
                ]}
                noRecordsMessage={
                  filterVals.current.size > 1
                    ? "No Records Found"
                    : "No studies exist for this sponsor"
                }
                {...studyDataTableProps}
              />
            </div>
          </Paper>
        </div>
      </MddForm>
    </div>
  );
};

export const BktShowSponsorStudies = withMddLookups([
  "demographics",
  "indications",
  "therapeuticAreas"
])(withMddSponsor()(_BktShowSponsorStudies));
