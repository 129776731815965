import { store } from "../utils";
import { IAction } from "front-end-lib/core";
import { call, put } from "redux-saga/effects";
import { ILookup } from "../model";
import { LookupService } from "../services";
import { eLookupActionTypes } from "../store";
import { initSaga, overrideSaga } from "../utils/sagaHelper";

export enum eLookupSaga {
  FETCH_DEMOGRAPHICS = "@@lookup/FETCH_DEMOGRAPHICS",
  WATCH_DEMOGRAPHICS = "@@lookup/WATCH_DEMOGRAPHICS",
  FETCH_INDICATIONS = "@@lookup/FETCH_INDICATIONS",
  WATCH_INDICATIONS = "@@lookup/WATCH_INDICATIONS",
  FETCH_THERAPEUTIC_AREAS = "@@lookup/FETCH_THERAPEUTIC_AREAS",
  WATCH_THERAPEUTIC_AREAS = "@@lookup/WATCH_THERAPEUTIC_AREAS",
  FETCH_COUNTRIES = "@@lookup/FETCH_COUNTRIES",
  WATCH_FETCH_COUNTRIES = "@@lookup/WATCH_FETCH_COUNTRIES",
  FETCH_SPECIALITIES = "@@lookup/FETCH_SPECIALITIES",
  WATCH_SPECIALITIES = "@@lookup/WATCH_SPECIALITIES",
  FETCH_SPONSORS = "@@lookup/FETCH_SPONSORS",
  FETCH_SPONSORS_SUCCESS = "@@lookup/FETCH_SPONSORS_SUCCESS",
  WATCH_FETCH_SPONSORS = "@@lookup/WATCH_FETCH_SPONSORS",
  FETCH_SITES = "@@lookup/FETCH_SITES",
  WATCH_SITES = "@@lookup/WATCH_SITES",
  FETCH_SITES_SUCCESS = "@@lookup/FETCH_SITES_SUCCESS",
  FETCH_STATES = "@@lookup/FETCH_STATES",
  FETCH_STATES_SUCCESS = "@@lookup/FETCH_STATES_SUCCESS",
  WATCH_STATES = "@@lookup/WATCH_FETCH_STATES",
  FETCH_ROLES = "@@lookup/FETCH_ROLES",
  FETCH_ROLES_SUCCESS = "@@lookup/FETCH_ROLES_SUCCESS",
  WATCH_ROLES = "@@lookup/WATCH_ROLES",
  FETCH_STUDY_PROTOCOLS = "@@lookup/FETCH_STUDY_PROTOCOLS",
  FETCH_STUDY_PROTOCOLS_SUCCESS = "@@lookup/FETCH_STUDY_PROTOCOLS_SUCCESS",
  WATCH_STUDY_PROTOCOLS = "@@lookup/WATCH_STUDY_PROTOCOLS",
  FETCH_QUALIFICATION_TYPES = "@@lookup/FETCH_QUALIFICATION_TYPES",
  WATCH_QUALIFICATION_TYPES = "@@lookup/WATCH_QUALIFICATION_TYPES",
  FETCH_QUALIFICATION_TYPES_SUCCESS = "@@lookup/FETCH_QUALIFICATION_TYPES_SUCCESS",
  FETCH_TIMEZONES = "@@lookup/FETCH_TIMEZONES",
  WATCH_TIMEZONES = "@@lookup/WATCH_TIMEZONES",
  FETCH_TIMEZONES_SUCCESS = "@@lookup/FETCH_TIMEZONES_SUCCESS"
}

initSaga(
  function* () {
    return yield call(LookupService.getDemographics);
  },
  eLookupSaga.FETCH_DEMOGRAPHICS,
  eLookupSaga.WATCH_DEMOGRAPHICS
);

initSaga(
  function* () {
    return yield call(LookupService.getIndications);
  },
  eLookupSaga.FETCH_INDICATIONS,
  eLookupSaga.WATCH_INDICATIONS
);

initSaga(
  function* () {
    return yield call(LookupService.getTherapeuticAreas);
  },
  eLookupSaga.FETCH_THERAPEUTIC_AREAS,
  eLookupSaga.WATCH_THERAPEUTIC_AREAS
);

initSaga(
  function* () {
    return yield call(LookupService.getSpecialities);
  },
  eLookupSaga.FETCH_SPECIALITIES,
  eLookupSaga.WATCH_SPECIALITIES
);

initSaga(
  function* () {
    return yield call(LookupService.getCountries);
  },
  eLookupSaga.FETCH_COUNTRIES,
  eLookupSaga.WATCH_FETCH_COUNTRIES
);

initSaga(
  function* fetchSponsors() {
    const payload = yield call(LookupService.getSponsors);
    yield put({ type: eLookupSaga.FETCH_SPONSORS_SUCCESS, payload });
  },
  eLookupSaga.FETCH_SPONSORS,
  eLookupSaga.WATCH_FETCH_SPONSORS
);

initSaga(
  function* lookupStatesSaga() {
    const payload = yield call(LookupService.getStates);
    yield put({ type: eLookupSaga.FETCH_STATES_SUCCESS, payload });
  },
  eLookupSaga.FETCH_STATES,
  eLookupSaga.WATCH_STATES
);

initSaga(
  function* lookupSitesSaga() {
    const payload = yield call(LookupService.getSites);
    yield put({ type: eLookupSaga.FETCH_SITES_SUCCESS, payload });
  },
  eLookupSaga.FETCH_SITES,
  eLookupSaga.WATCH_SITES
);

initSaga(
  function* lookupRolesSaga() {
    const payload = yield call(LookupService.getRoles);
    yield put({ type: eLookupSaga.FETCH_ROLES_SUCCESS, payload });
  },
  eLookupSaga.FETCH_ROLES,
  eLookupSaga.WATCH_ROLES
);

initSaga(
  function* lookupStudyProtocols(action: IAction) {
    const payload = yield call(LookupService.getStudyProtocols, action.payload);
    yield put({ type: eLookupSaga.FETCH_STUDY_PROTOCOLS_SUCCESS, payload });
  },
  eLookupSaga.FETCH_STUDY_PROTOCOLS,
  eLookupSaga.WATCH_STUDY_PROTOCOLS
);

initSaga(
  function* lookupQualificationTypes() {
    return yield call(LookupService.getQualificationTypes);
  },
  eLookupSaga.FETCH_QUALIFICATION_TYPES,
  eLookupSaga.WATCH_QUALIFICATION_TYPES
);

initSaga(
  function* fetchTimezones() {
    const payload = yield call(LookupService.getTimezones);

    yield put({ type: eLookupSaga.FETCH_TIMEZONES_SUCCESS, payload });
    return payload;
  },
  eLookupSaga.FETCH_TIMEZONES,
  eLookupSaga.WATCH_TIMEZONES
);

export const overrideLookupSagas = () => {
  overrideSaga(
    function* lookupQualificationTypes() {
      yield store.dispatch({
        type: eLookupActionTypes.SET_LOOKUP,
        payload: {
          qualificationTypes: [
            {
              value: "RATERIDENTIFICATION",
              label: "Rater Identification"
            },
            {
              value: "RATEREXPERIENCE",
              label: "Rater Experience"
            },
            {
              value: "TRAINING",
              label: "Training"
            },
            {
              value: "ENRICHEDTRAINING",
              label: "Enriched Training"
            },
            { value: "CORATING", label: "Co-Rating" }
          ]
        }
      });
    },
    eLookupSaga.FETCH_QUALIFICATION_TYPES,
    eLookupSaga.WATCH_QUALIFICATION_TYPES
  );

  overrideSaga(
    function* dispatchCountries() {
      yield store.dispatch({
        type: eLookupActionTypes.SET_LOOKUP,
        payload: {
          countries: [
            { value: "CA", label: "Canada" } as ILookup,
            { value: "US", label: "United States" } as ILookup
          ]
        }
      });
    },
    eLookupSaga.FETCH_COUNTRIES,
    eLookupSaga.WATCH_FETCH_COUNTRIES
  );

  overrideSaga(
    function* dispatchStudyProtocols() {
      yield store.dispatch({
        type: eLookupActionTypes.SET_LOOKUP,
        payload: {
          protocols: [
            { value: "1", label: "TestProtocol1" },
            { value: "2", label: "TestProtocol2" }
          ]
        }
      });
    },
    eLookupSaga.FETCH_STUDY_PROTOCOLS,
    eLookupSaga.WATCH_STUDY_PROTOCOLS
  );

  overrideSaga(
    function* lookupRolesSaga() {
      yield put({
        type: eLookupSaga.FETCH_ROLES_SUCCESS,
        payload: [
          {
            roleId: "e088796f-f83d-ea11-8c6e-00155d0f1e6b",
            name: "Clinical Research Associate",
            isSitePi: false
          },
          {
            roleId: "df88796f-f83d-ea11-8c6e-00155d0f1e6b",
            name: "Principal Investigator",
            isSitePi: true
          },
          {
            roleId: "de88796f-f83d-ea11-8c6e-00155d0f1e6b",
            name: "Rater",
            isSitePi: false
          },
          {
            roleId: "dd88796f-f83d-ea11-8c6e-00155d0f1e6b",
            name: "Study Coord.",
            isSitePi: false
          }
        ]
      });
    },
    eLookupSaga.FETCH_ROLES,
    eLookupSaga.WATCH_ROLES
  );

  overrideSaga(
    function* lookupRolesSaga() {
      yield put({
        type: eLookupSaga.FETCH_ROLES_SUCCESS,
        payload: [
          {
            roleId: "e088796f-f83d-ea11-8c6e-00155d0f1e6b",
            name: "Clinical Research Associate",
            isSitePi: false
          },
          {
            roleId: "df88796f-f83d-ea11-8c6e-00155d0f1e6b",
            name: "Principal Investigator",
            isSitePi: true
          },
          {
            roleId: "de88796f-f83d-ea11-8c6e-00155d0f1e6b",
            name: "Rater",
            isSitePi: false
          },
          {
            roleId: "dd88796f-f83d-ea11-8c6e-00155d0f1e6b",
            name: "Study Coord.",
            isSitePi: false
          }
        ]
      });
    },
    eLookupSaga.FETCH_ROLES,
    eLookupSaga.WATCH_ROLES
  );

  overrideSaga(
    function* lookupSitesSaga() {
      yield put({
        type: eLookupSaga.FETCH_SITES_SUCCESS,
        payload: [
          {
            value: "1",
            label: "Site1"
          },
          {
            value: "2",
            label: "Site2"
          }
        ]
      });
    },
    eLookupSaga.FETCH_SITES,
    eLookupSaga.WATCH_SITES
  );

  overrideSaga(
    function* lookupStatesSaga() {
      yield put({
        type: eLookupSaga.FETCH_STATES_SUCCESS,
        payload: [
          {
            value: "999",
            label: "Texas"
          },
          {
            value: "2",
            label: "Ohio"
          }
        ]
      });
    },
    eLookupSaga.FETCH_STATES,
    eLookupSaga.WATCH_STATES
  );

  overrideSaga(
    function* fetchSponsors() {
      yield put({
        type: eLookupSaga.FETCH_SPONSORS_SUCCESS,
        payload: [
          {
            value: "1",
            label: "Sponsor 1"
          },
          {
            value: "2",
            label: "Sponsor 2"
          },
          {
            value: "3",
            label: "Sponsor 3"
          }
        ]
      });
    },
    eLookupSaga.FETCH_SPONSORS,
    eLookupSaga.WATCH_FETCH_SPONSORS
  );

  overrideSaga(
    function* fetchTimezones() {
      const payload = [
        {
          id: "Africa/Accra",
          offset: "+00"
        },
        {
          id: "America/Cancun",
          offset: "-05"
        },
        {
          id: "US/Eastern",
          offset: "-05"
        }
      ];

      yield put({
        type: eLookupSaga.FETCH_TIMEZONES_SUCCESS,
        payload
      });

      return payload;
    },
    eLookupSaga.FETCH_TIMEZONES,
    eLookupSaga.WATCH_TIMEZONES
  );
};
