import { call, put } from "redux-saga/effects";
import { IAction, SagaService, eHTTPMethods } from "front-end-lib";

import { LookupService } from "../../services";
import {
  IApplicationState,
  openErrorSnackBar,
  openSuccessSnackBar
} from "../../store";
import {
  eResConfig,
  IServerState,
  ILocalStorageState,
  IEditItem
} from "../../store/resConfig/types";
import { callApi, store } from "../../utils";
import { initSaga } from "../../utils/sagaHelper";
import {
  set as setLocalStorageItem,
  get as getLocalStorageItem
} from "../../utils/localStorageHelper";
import { IIdNamePair } from "../../model";
import { eApiNames } from "../../constants";
import { IResVersionListItem } from "../../model/interfaces/iResVersionListItem";
import { convertToServerState, convertToUIState } from "./utils";

const getLsKey = (state: IApplicationState, studyId?: string) => {
  const {
    user: { Email: userId },
    study: {
      currentStudy: { StudyId: storeStudyId }
    }
  } = state;

  return `${userId}_${studyId || storeStudyId}`;
};

const defaultLsState = {
  version: "0.0",
  demographics: [],
  indications: [],
  scales: []
};

export function* loadResConfig({ payload: { studyId, resId } }: IAction) {
  try {
    const serverConfig: IServerState = yield call(() =>
      callApi(
        eHTTPMethods.GET,
        `/studies/${studyId}/res-configurations/${resId}`,
        undefined,
        undefined,
        eApiNames.STUDY_API
      )
    );
    const lsKey = getLsKey(store.getState(), studyId);
    let currentLsState = getLocalStorageItem<ILocalStorageState>(lsKey);
    const isOtherVersion =
      !!currentLsState && currentLsState.version !== serverConfig.version;
    if (!currentLsState || isOtherVersion) {
      currentLsState = defaultLsState;
    }

    const config = convertToUIState(serverConfig, currentLsState);

    yield store.dispatch({
      type: eResConfig.GET_RES_CONFIG_SUCCESS,
      payload: config
    });

    if (isOtherVersion) {
      yield setLocalStorageItem(lsKey);
    }
  } catch (e) {
    yield store.dispatch(openErrorSnackBar(e.message));
  }
}

initSaga(
  loadResConfig,
  eResConfig.GET_RES_CONFIG,
  eResConfig.GET_RES_CONFIG_WATCH
);

export function* saveResConfig() {
  try {
    const state: IApplicationState = yield store.getState();
    const {
      resConfig,
      study: {
        currentStudy: { StudyId: studyId }
      }
    } = state;

    const toSave = convertToServerState(resConfig);

    const result: IServerState = yield call(() =>
      callApi(
        eHTTPMethods.PUT,
        `/studies/${studyId}/res-configurations`,
        toSave,
        undefined,
        eApiNames.STUDY_API
      )
    );

    const lsKey = getLsKey(state);
    yield setLocalStorageItem(lsKey);

    const config = convertToUIState(result, defaultLsState);

    yield store.dispatch({
      type: eResConfig.GET_RES_CONFIG_SUCCESS,
      payload: config
    });

    yield store.dispatch(
      openSuccessSnackBar("The configuration was successfully saved")
    );
  } catch (e) {
    yield store.dispatch(openErrorSnackBar(e.message));
  }
}

initSaga(
  saveResConfig,
  eResConfig.SAVE_RES_CONFIG,
  eResConfig.SAVE_RES_CONFIG_WATCH
);

initSaga(
  function* resetResConfig({ payload: { studyId } }: IAction) {
    const userId = store.getState().user.Email;
    yield setLocalStorageItem(`${userId}_${studyId}`);
    yield store.dispatch({ type: eResConfig.RESET });
  },
  eResConfig.RESET,
  eResConfig.RESET_WATCH
);

initSaga(
  function* loadResConfigList({ payload: { id } }: IAction) {
    const result: IResVersionListItem[] = yield call(() =>
      callApi(
        eHTTPMethods.GET,
        `/studies/${id}/res-configurations`,
        undefined,
        undefined,
        eApiNames.STUDY_API
      )
    );

    yield put({
      type: eResConfig.GET_RES_CONFIG_LIST_SUCCESS,
      payload: result
    });
  },
  eResConfig.GET_RES_CONFIG_LIST,
  eResConfig.GET_RES_CONFIG_LIST_WATCH
);

initSaga(
  function* createResConfig({ payload: { id } }: IAction) {
    const result: { studyRaterExperienceSurveyId: string } = yield call(() =>
      callApi(
        eHTTPMethods.POST,
        `/studies/${id}/res-configurations`,
        undefined,
        undefined,
        eApiNames.STUDY_API
      )
    );

    yield put({
      type: eResConfig.CREATE_RES_CONFIG_SUCCESS,
      payload: { id: result.studyRaterExperienceSurveyId }
    });
  },
  eResConfig.CREATE_RES_CONFIG,
  eResConfig.CREATE_RES_CONFIG_WATCH
);

initSaga(
  function* editItems({ payload: { edits, section } }: IAction) {
    const state = store.getState();
    const {
      resConfig: { version }
    } = state;
    const lsKey = getLsKey(state);
    const currentLsState =
      getLocalStorageItem<ILocalStorageState>(lsKey) ||
      ({ version } as ILocalStorageState);
    currentLsState[section] = edits;
    yield setLocalStorageItem(lsKey, currentLsState);

    yield store.dispatch({
      type: eResConfig.EDIT_ITEMS,
      payload: { section, edits }
    });
  },
  eResConfig.EDIT_ITEMS,
  eResConfig.EDIT_ITEMS_WATCH
);

interface DeleteItemsProps extends IAction {
  payload: {
    edits: IEditItem[];
    deleteItem: IEditItem;
    items: IEditItem[];
    section: string;
  };
}

export function* deleteItems({
  payload: { edits, deleteItem, items, section }
}: DeleteItemsProps) {
  const toRemove = deleteItem.childId
    ? [deleteItem]
    : items.filter((m) => m.parentId === deleteItem.parentId);
  const newEdits = toRemove.reduce((prev, next) => {
    const existingEdit = prev.find(
      (m) => m.parentId === next.parentId && m.childId === next.childId
    );

    return existingEdit
      ? prev.filter((m) => m !== existingEdit)
      : [...prev, { ...next, softDeleted: true }];
  }, edits);

  yield SagaService.dispatchSaga({
    type: eResConfig.EDIT_ITEMS,
    payload: { section: section, edits: newEdits }
  });
}

initSaga(deleteItems, eResConfig.DELETE_ITEMS, eResConfig.DELETE_ITEMS_WATCH);

initSaga(
  function* loadDemographics() {
    try {
      const demographics: IIdNamePair[] = yield call(
        LookupService.getDemographicTypes
      );
      yield store.dispatch({
        type: eResConfig.GET_DEMOGRAPHICS_SUCCESS,
        payload: demographics.sort((a, b) => a.name.localeCompare(b.name))
      });
    } catch (e) {
      yield store.dispatch(openErrorSnackBar(e.message));
    }
  },
  eResConfig.GET_DEMOGRAPHICS,
  eResConfig.GET_DEMOGRAPHICS_WATCH
);

initSaga(
  function* loadIndications() {
    try {
      const indications: IIdNamePair[] = yield call(
        LookupService.getIndicationTypes
      );
      yield store.dispatch({
        type: eResConfig.GET_INDICATIONS_SUCCESS,
        payload: indications.sort((a, b) => a.name.localeCompare(b.name))
      });
    } catch (e) {
      yield store.dispatch(openErrorSnackBar(e.message));
    }
  },
  eResConfig.GET_INDICATIONS,
  eResConfig.GET_INDICATIONS_WATCH
);

initSaga(
  function* loadScales({ payload: { studyId } }) {
    try {
      const scales: any = yield call(() =>
        callApi(
          eHTTPMethods.GET,
          `/studies/${studyId}/scales`,
          undefined,
          undefined,
          eApiNames.STUDY_API
        )
      );
      yield store.dispatch({
        type: eResConfig.GET_SCALES_SUCCESS,
        payload: scales.sort((a, b) => a.name.localeCompare(b.name))
      });
    } catch (e) {
      yield store.dispatch(openErrorSnackBar(e.message));
    }
  },
  eResConfig.GET_SCALES,
  eResConfig.GET_SCALES_WATCH
);

initSaga(
  function* DeleteResDraft({ payload: { studyId, studyResId } }: IAction) {
    try {
      yield call(() =>
        callApi(
          eHTTPMethods.DELETE,
          `/studies/${studyId}/res-configurations/${studyResId}`,
          undefined,
          undefined,
          eApiNames.STUDY_API
        )
      );

      yield put({ type: eResConfig.DELETE_RES_DRAFT_SUCCESS });

      yield store.dispatch(
        openSuccessSnackBar("The configuration was successfully deleted")
      );
    } catch (e) {
      yield store.dispatch(openErrorSnackBar(e.message));
    }
  },
  eResConfig.DELETE_RES_DRAFT,
  eResConfig.DELETE_RES_DRAFT_WATCH
);

initSaga(
  function* PublishResDraft({ payload: { studyId, studyResId } }: IAction) {
    try {
      yield call(() =>
        callApi(
          eHTTPMethods.PUT,
          `/studies/${studyId}/res-configurations/${studyResId}/publish`,
          undefined,
          undefined,
          eApiNames.STUDY_API
        )
      );

      yield put({ type: eResConfig.PUBLISH_RES_DRAFT_SUCCESS });

      yield store.dispatch(
        openSuccessSnackBar("The configuration was successfully published")
      );
    } catch (e) {
      yield store.dispatch(openErrorSnackBar(e.message));
    }
  },
  eResConfig.PUBLISH_RES_DRAFT,
  eResConfig.PUBLISH_RES_DRAFT_WATCH
);
