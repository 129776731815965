import { isEmpty } from "lodash";
import { call, put, takeLatest, all } from "redux-saga/effects";
import { SagaService, IAction } from "front-end-lib/core";
import { Site, ISite } from "../model";
import { eUtilSaga } from "../sagas";
import { eSiteActionTypes } from "../store";
import { SiteService, LookupService } from "../services";
import { openSuccessSnackBar } from "../store";
import { store, history, overrideSaga } from "../utils";
import { initSaga } from "../utils/sagaHelper";

import { ConfigOptions } from "../config";

export enum eSiteSaga {
  FETCH_SITES = "@@site/FETCH_SITES",
  FETCH_SITES_SUCCESS = "@@site/FETCH_SITES_SUCCESS",
  WATCH_SITES = "@@site/WATCH_SITES",
  FETCH_SITE = "@@site/FETCH_SITE",
  FETCH_SITE_SUCCESS = "@@site/FETCH_SITE_SUCCESS",
  WATCH_SITE = "@@site/WATCH_SITE",
  FETCH_SITE_LOOKUPS = "@@site/FETCH_SITE_LOOKUPS",
  FETCH_SITE_LOOKUPS_SUCCESS = "@@site/FETCH_SITE_LOOKUPS_SUCCESS",
  WATCH_SITE_LOOKUPS = "@@site/WATCH_SITE_LOOKUPS",
  SAVE_SITE = "@@site/SAVE_SITE",
  SAVE_SITE_SUCCESS = "@@site/SAVE_SITE_SUCCESS",
  WATCH_SAVE_SITE = "@@site/WATCH_SAVE_SITE",
  FETCH_SITE_COMMENTS = "@@site/FETCH_SITE_COMMENTS",
  FETCH_SITE_COMMENTS_SUCCESS = "@@site/FETCH_SITE_COMMENTS_SUCCESS",
  WATCH_SITE_COMMENTS = "@@site/WATCH_SITE_COMMENTS",
  MODIFY_SITE_COMMENT = "@@site/MODIFY_SITE_COMMENT",
  MODIFY_SITE_COMMENT_SUCCESS = "@@site/MODIFY_SITE_COMMENT_SUCCESS",
  WATCH_SITE_COMMENT_SAVE = "@@site/WATCH_SITE_COMMENT_SAVE"
}

initSaga(
  function* fetchSitesSaga(action: IAction) {
    yield SagaService.dispatchSaga({ type: eUtilSaga.LOADING, payload: true });
    const sites = yield call(SiteService.getSites, action.payload);
    yield put({ type: eSiteSaga.FETCH_SITES_SUCCESS, payload: sites });
  },
  eSiteSaga.FETCH_SITES,
  eSiteSaga.WATCH_SITES
);

initSaga(
  function* fetchSiteSaga(action: IAction) {
    const site = yield call(SiteService.getSite, action.payload);
    store.dispatch({
      type: eSiteActionTypes.SET_SITE,
      payload: site
    });
    yield put({ type: eSiteSaga.FETCH_SITE_SUCCESS, payload: site });
  },
  eSiteSaga.FETCH_SITE,
  eSiteSaga.WATCH_SITE
);

export function* fetchSiteCommentsSaga(action: IAction) {
  try {
    yield SagaService.dispatchSaga({ type: eUtilSaga.LOADING, payload: true });
    const siteComments = yield call(
      SiteService.getSiteComments,
      action.payload
    );
    yield put({
      type: eSiteSaga.FETCH_SITE_COMMENTS_SUCCESS,
      payload: siteComments
    });
  } catch (e) {
    console.error(e);
  } finally {
    yield SagaService.dispatchSaga({ type: eUtilSaga.LOADING, payload: false });
  }
}

export function* watchSiteCommentsSagas() {
  yield takeLatest(
    eSiteSaga.FETCH_SITE_COMMENTS.toString(),
    fetchSiteCommentsSaga
  );
}

export function* fetchsiteLookups(action: IAction) {
  try {
    yield SagaService.dispatchSaga({ type: eUtilSaga.LOADING, payload: true });
    const { countries } = yield all({
      countries: call(LookupService.getCountries)
    });

    yield put({
      type: eSiteSaga.FETCH_SITE_LOOKUPS_SUCCESS,
      payload: { countries }
    });
  } catch (e) {
    console.log(e);
  } finally {
    yield SagaService.dispatchSaga({ type: eUtilSaga.LOADING, payload: false });
  }
}

export function* watchFetchsiteLookups() {
  yield takeLatest(eSiteSaga.FETCH_SITE_LOOKUPS.toString(), fetchsiteLookups);
}

initSaga(
  function* saveSiteSaga(action: IAction) {
    const site = yield call(SiteService.saveSite, action.payload);
    if (isEmpty(site.stateId)) {
      site.stateId = ConfigOptions.EMPTY_GUID;
    }
    yield put({ type: eSiteSaga.SAVE_SITE_SUCCESS, payload: site });
    yield history.push(`/site/${site.siteId}`);
    yield store.dispatch(openSuccessSnackBar("Save successful"));
  },
  eSiteSaga.SAVE_SITE,
  eSiteSaga.WATCH_SAVE_SITE
);

export function* modifySiteCommentSaga(action: IAction) {
  try {
    yield SagaService.dispatchSaga({ type: eUtilSaga.LOADING, payload: true });
    const siteComment = yield call(SiteService.saveSiteComment, action.payload);
    yield put({
      type: eSiteSaga.MODIFY_SITE_COMMENT_SUCCESS,
      payload: siteComment
    });
    yield history.push(`/site/${siteComment.siteId}/comment`);
    yield store.dispatch(openSuccessSnackBar("Save successful"));
  } catch (e) {
    console.log(e);
  } finally {
    yield SagaService.dispatchSaga({ type: eUtilSaga.LOADING, payload: false });
  }
}

export function* watchSaveSiteCommentSagas() {
  yield takeLatest(
    eSiteSaga.MODIFY_SITE_COMMENT.toString(),
    modifySiteCommentSaga
  );
}

export const overrideSiteSagas = () => {
  overrideSaga(
    function* dispatchSites() {
      yield put({
        type: eSiteSaga.FETCH_SITES_SUCCESS,
        payload: [
          Object.assign(new Site(), {
            SiteId: "1",
            SiteName: "TestSite1",
            Country: "United States",
            Zip: "00001"
          }),
          Object.assign(new Site(), {
            SiteId: "2",
            SiteName: "TestSite2",
            Country: "Canada",
            Zip: "00002"
          })
        ]
      });
    },
    eSiteSaga.FETCH_SITES,
    eSiteSaga.WATCH_SITES
  );

  overrideSaga(
    function* saveSiteSaga(action: IAction) {
      yield put({ type: eSiteSaga.SAVE_SITE_SUCCESS, payload: undefined });
      yield store.dispatch(openSuccessSnackBar("Save successful"));
    },
    eSiteSaga.SAVE_SITE,
    eSiteSaga.WATCH_SAVE_SITE
  );

  // override FETCH_SITE for testing
  overrideSaga(
    function* fetchSiteSaga() {
      const site = Object.assign(new Site(), {
        address1: "test address1",
        address2: "test address2",
        addressId: "abc-123",
        city: "test city",
        countryName: "United States",
        countryValue: "US",
        phone: "test phone",
        siteId: "1",
        siteName: "test site name",
        siteNumber: "test site number",
        stateId: "999",
        stateName: "Texas",
        zipCode: "12345",
        timeZone: "America/New_York"
      }) as ISite;
      store.dispatch({
        type: eSiteActionTypes.SET_SITE,
        payload: site
      });
      yield put({ type: eSiteSaga.FETCH_SITE_SUCCESS, payload: site });
    },
    eSiteSaga.FETCH_SITE,
    eSiteSaga.WATCH_SITE
  );
};
