import * as React from "react";
import { BktSelect, BktValidatedTextField } from "front-end-lib";
import { isEqual } from "lodash";
import Button from "@material-ui/core/Button";
import { eLookupSaga, eSiteSaga } from "../../../sagas";
import { withMddLookups } from "../../higher-order/WithMddLookups/WithMddLookups";
import { ConfigOptions } from "../../../config";
import { isLoading, runSagaAsync } from "../../../utils";
import { MAX_TEXT_LENGTH } from "../../../constants";
import {
  ILookup,
  IMatch,
  ISite,
  ITimeZoneLookup,
  Site,
  SiteDTO,
  Validations
} from "../../../model";
import { BktBreadcrumb, MddForm } from "../../common";
import { MddAddEditSiteConfirmDialog } from "./MddAddEditSiteConfirmDialog";

interface IMddAddEditSiteProps {
  match: IMatch<{
    id: string;
  }>;
  countries: ILookup[];
}

const MddAddEditSiteComponent = (props: IMddAddEditSiteProps) => {
  const [siteInfo, setSiteInfo] = React.useState<ISite>(new Site());
  const [newSiteInfo, setNewSiteInfo] = React.useState<ISite>(new Site());

  const [lookupData, setLookupData] = React.useState<{
    statesList: ILookup[];
    timeZones: ILookup[];
  }>({ statesList: [], timeZones: [] });
  const [validationErrors, setValidationErrors] = React.useState({
    siteName: false,
    city: false,
    zipCode: false,
    phoneNumber: false
  });
  const [formState, setFormState] = React.useState({
    disableState: false,
    countryHelperText: "",
    timezoneHelperText: "",
    isCountryError: false,
    isTimeZoneError: false
  });

  const [confirmSaveOpen, setConfirmSaveOpen] = React.useState(false);

  const getSite = async () => {
    const { id } = props.match.params;
    const siteInfo = await runSagaAsync<ISite>({
      startType: eSiteSaga.FETCH_SITE,
      successType: eSiteSaga.FETCH_SITE_SUCCESS,
      payload: id
    });
    setSiteInfo(siteInfo);
    setNewSiteInfo(siteInfo);
  };

  const getStates = async () => {
    const states = await runSagaAsync<ILookup[]>({
      startType: eLookupSaga.FETCH_STATES,
      successType: eLookupSaga.FETCH_STATES_SUCCESS
    });
    setLookupData((prevData) => {
      return { ...prevData, statesList: states };
    });
  };

  const getTimeZones = async () => {
    const timeZones = await runSagaAsync<ITimeZoneLookup[]>({
      startType: eLookupSaga.FETCH_TIMEZONES,
      successType: eLookupSaga.FETCH_TIMEZONES_SUCCESS
    });

    const tzLookup = timeZones.map((tz: ITimeZoneLookup) => {
      return { value: tz.id, label: tz.id };
    });

    setLookupData((prevData) => {
      return { ...prevData, timeZones: tzLookup };
    });
  };

  React.useEffect(() => {
    const { id } = props.match.params;
    if (id) {
      getSite();
    }
    getStates();
    getTimeZones();

    // eslint-disable-next-line
  }, []);

  const getCurrentLocation = () => {
    const { SiteId } = newSiteInfo;
    const { SiteName, SiteNumber } = siteInfo;
    return SiteId ? `${SiteName} (${SiteNumber})` : "Add New";
  };

  const disableSaveButton = () => {
    return (
      !newSiteInfo.SiteName ||
      !newSiteInfo.CountryValue ||
      !newSiteInfo.Timezone ||
      validationErrors.siteName ||
      validationErrors.city ||
      validationErrors.phoneNumber ||
      validationErrors.zipCode ||
      isEqual(newSiteInfo, siteInfo) ||
      isLoading(`undefined_${eSiteSaga.SAVE_SITE}`)
    );
  };

  const handleSaveClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (newSiteInfo.Timezone !== siteInfo.Timezone) {
      setConfirmSaveOpen(true);
      return;
    }

    processSave();
  };

  const processSave = async () => {
    const {
      SiteId,
      SiteName,
      CountryValue,
      Country,
      State,
      Address1,
      Address2,
      City,
      Zip,
      Phone,
      Timezone
    } = newSiteInfo;

    const payload = new SiteDTO(
      SiteName ? SiteName.trim() : "",
      CountryValue,
      Country,
      Address1 ? Address1.trim() : "",
      Address2 ? Address2.trim() : "",
      City ? City.trim() : "",
      newSiteInfo.StateId === ConfigOptions.EMPTY_GUID
        ? ""
        : newSiteInfo.StateId,
      State,
      Zip ? Zip.trim() : "",
      Phone ? Phone.trim() : "",
      SiteId,
      Timezone
    );

    const savedSite = await runSagaAsync<ISite>({
      startType: eSiteSaga.SAVE_SITE,
      successType: eSiteSaga.SAVE_SITE_SUCCESS,
      payload
    });

    // on successful save set the site info to the new site info so save is disabled
    setSiteInfo(savedSite);
    setNewSiteInfo(savedSite);
  };

  const updateSiteField = (fieldName: keyof ISite, value: any) => {
    const doUpdate = (prevState: ISite, fieldName: keyof ISite, value: any) => {
      const updatedSite = new Site();
      Object.assign(updatedSite, prevState);
      updatedSite[fieldName] = value;
      return updatedSite;
    };

    setNewSiteInfo((prev) => doUpdate(prev, fieldName, value));
  };

  const { countries } = props;

  const enableState =
    newSiteInfo.CountryValue === ConfigOptions.US_COUNTRY_CODE;

  return (
    <React.Fragment>
      <div className="mdd-main-div">
        <BktBreadcrumb
          rootText="All Sites"
          rootUrl="/site"
          currentLocation={getCurrentLocation()}
        />
        <MddForm
          keys={[
            `undefined_${eSiteSaga.SAVE_SITE}`,
            `HOCLookup_${eLookupSaga.FETCH_COUNTRIES}`,
            `_${eLookupSaga.FETCH_STATES}`,
            `_${eLookupSaga.FETCH_TIMEZONES}`
          ]}
        >
          <div className="mdd-form--body">
            <BktValidatedTextField
              validationForOnChange={[Validations.required]}
              validationForOnBlur={[Validations.required]}
              validationerror={(validationError: boolean) =>
                setValidationErrors({
                  ...validationErrors,
                  siteName: validationError
                })
              }
              textFieldProps={{
                textProps: {
                  id: "siteName",
                  className: "mdd-form--text",
                  label: "Site Name",
                  placeholder: "Site Name",
                  inputProps: {
                    maxLength: MAX_TEXT_LENGTH.SITE_NAME
                  },
                  variant: "standard",
                  required: true,
                  onChange: (event: any) =>
                    updateSiteField("SiteName", event.target.value),
                  type: "text",
                  value: newSiteInfo.SiteName || ""
                }
              }}
            />
            {newSiteInfo.SiteId && (
              <BktValidatedTextField
                textFieldProps={{
                  textProps: {
                    id: "siteId",
                    className: "mdd-form--text",
                    disabled: true,
                    label: "Site Id",
                    placeholder: "Site Id",
                    variant: "standard",
                    type: "text",
                    value: newSiteInfo.SiteNumber || ""
                  }
                }}
              />
            )}
            <BktValidatedTextField
              textFieldProps={{
                textProps: {
                  id: "siteAddress1",
                  className: "mdd-form--text",
                  label: "Address 1",
                  placeholder: "Address 1",
                  inputProps: {
                    maxLength: MAX_TEXT_LENGTH.ADDRESS1
                  },
                  type: "text",
                  variant: "standard",
                  value: newSiteInfo.Address1 || "",
                  onChange: (event: any) =>
                    updateSiteField("Address1", event.target.value)
                }
              }}
            />
            <BktValidatedTextField
              textFieldProps={{
                textProps: {
                  id: "siteAddress2",
                  className: "mdd-form--text",
                  label: "Address 2",
                  placeholder: "Address 2",
                  inputProps: {
                    maxLength: MAX_TEXT_LENGTH.ADDRESS2
                  },
                  type: "text",
                  variant: "standard",
                  value: newSiteInfo.Address2 || "",
                  onChange: (event) =>
                    updateSiteField("Address2", event.target.value)
                }
              }}
            />
            <BktValidatedTextField
              textFieldProps={{
                textProps: {
                  id: "city",
                  className: "mdd-form--text",
                  label: "City",
                  placeholder: "City",
                  inputProps: { maxLength: MAX_TEXT_LENGTH.CITY },
                  type: "text",
                  variant: "standard",
                  value: newSiteInfo.City || "",
                  onChange: (event: any) =>
                    updateSiteField("City", event.target.value)
                }
              }}
            />
            <BktSelect
              menuProps={{}}
              textProps={{
                id: "bktSelectFieldCountry",
                label: "Country",
                placeholder: "Country",
                value: newSiteInfo.CountryValue || "",
                className: "mdd-form--text",
                onChange: (event) => {
                  updateSiteField("CountryValue", event.target.value);
                  updateSiteField("StateId", "");
                  setFormState({
                    ...formState,
                    isCountryError: false,
                    countryHelperText: ""
                  });
                },
                onBlur: (event) => {
                  if (!event.target.value) {
                    setFormState({
                      ...formState,
                      isCountryError: true,
                      countryHelperText: ConfigOptions.REQUIRED_HELPER_TEXT
                    });
                  }
                },
                required: true,
                error: formState.isCountryError,
                helperText: formState.countryHelperText
              }}
              selectData={countries}
            />
            <BktSelect
              menuProps={{}}
              textProps={{
                id: "bktSelectFieldState",
                label: "State",
                placeholder: "State",
                value: enableState ? newSiteInfo.StateId : "",
                className: "mdd-form--text",
                onChange: (event) =>
                  updateSiteField("StateId", event.target.value),

                disabled: !enableState
              }}
              defaultSelect={true}
              selectData={lookupData.statesList}
            />
            <BktValidatedTextField
              validationForOnChange={[Validations.zipCodeAllowedCharacters]}
              validationForOnBlur={[Validations.zipCodeAllowedCharacters]}
              validationerror={(validationError: boolean) =>
                setValidationErrors({
                  ...validationErrors,
                  zipCode: validationError
                })
              }
              textFieldProps={{
                textProps: {
                  id: "zipCode",
                  className: "mdd-form--text",
                  label: "Zip Code",
                  placeholder: "Zip Code",
                  inputProps: {
                    maxLength: MAX_TEXT_LENGTH.ZIP_CODE
                  },
                  variant: "standard",
                  type: "text",
                  value: newSiteInfo.Zip || "",
                  onChange: (event: any) =>
                    updateSiteField("Zip", event.target.value)
                }
              }}
            />
            <BktValidatedTextField
              validationForOnChange={[Validations.phoneNumberAllowedCharacters]}
              validationForOnBlur={[Validations.phoneNumberAllowedCharacters]}
              validationerror={(validationError: boolean) =>
                setValidationErrors({
                  ...validationErrors,
                  phoneNumber: validationError
                })
              }
              textFieldProps={{
                textProps: {
                  id: "phoneNumber",
                  className: "mdd-form--text",
                  label: "Phone",
                  placeholder: "Phone",
                  inputProps: {
                    maxLength: MAX_TEXT_LENGTH.PHONE_NUMBER
                  },
                  type: "text",
                  variant: "standard",
                  value: newSiteInfo.Phone || "",
                  onChange: (event) =>
                    updateSiteField("Phone", event.target.value)
                }
              }}
            />
            <BktSelect
              menuProps={{}}
              textProps={{
                id: "timeZone",
                label: "Time Zone",
                placeholder: "Time Zone",
                value: newSiteInfo.Timezone || "",
                className: "mdd-form--text",
                required: true,
                onChange: (event) => {
                  updateSiteField("Timezone", event.target.value);
                  setFormState({
                    ...formState,
                    isTimeZoneError: false,
                    timezoneHelperText: ""
                  });
                },
                onBlur: (event) => {
                  if (!event.target.value) {
                    setFormState({
                      ...formState,
                      isTimeZoneError: true,
                      timezoneHelperText: ConfigOptions.REQUIRED_HELPER_TEXT
                    });
                  }
                },
                error: formState.isTimeZoneError,
                helperText: formState.timezoneHelperText
              }}
              selectData={lookupData.timeZones}
            />

            <div className="mdd-form__button--container">
              <Button
                id="saveButtonId"
                data-testid="saveButton"
                color="primary"
                disableFocusRipple={true}
                disableRipple={true}
                variant="contained"
                type="submit"
                className="mdd-form__button"
                onClick={handleSaveClick}
                disabled={disableSaveButton()}
              >
                Save
              </Button>
            </div>
          </div>
        </MddForm>
      </div>
      <MddAddEditSiteConfirmDialog
        open={confirmSaveOpen}
        onClose={(isSave: boolean) => {
          setConfirmSaveOpen(false);
          if (isSave) {
            processSave();
          }
        }}
      />
    </React.Fragment>
  );
};

export const MddAddEditSite = withMddLookups(["countries"])(
  MddAddEditSiteComponent
);
