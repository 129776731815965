import { call, put, takeLatest, all } from "redux-saga/effects";
import { SagaService, IAction } from "front-end-lib/core";
import {
  StudyScale,
  StudySite,
  Study,
  StudyQualification,
  StudyProtocol,
  IComment
} from "../model";
import { eUtilSaga } from "../sagas";
import { StudyService, LookupService } from "../services";
import { openSuccessSnackBar, eStudyActionTypes } from "../store";
import { history, overrideSaga, store } from "../utils";
import { initSaga } from "../utils/sagaHelper";
import {
  mockScaleExperienceData,
  mockTrainingData,
  mockStudyData,
  mockCourseData,
  mockCountryScores,
  mockCourseThresholds,
  mockMasterScoresData,
  mockPersonnelAtSite,
  mockBulkLPAssignData,
  mockBulkLPs,
  mockPersonnelTrainingScores,
  mockCountryScoresData,
  mockTrainingCourseScores
} from "../config";
import { EXPERIENCE_STATUS_VALUE } from "../constants";
import { EXPERIENCE_STATUS_DISPLAY_VALUE } from "../constants";

export enum eStudySaga {
  FETCH_STUDIES = "@@study/FETCH_STUDIES",
  FETCH_STUDIES_SUCCESS = "@@study/FETCH_STUDIES_SUCCESS",
  WATCH_STUDIES = "@@study/WATCH_STUDIES",
  FETCH_STUDY = "@@study/FETCH_STUDY",
  FETCH_STUDY_SUCCESS = "@@study/FETCH_STUDY_SUCCESS",
  WATCH_STUDY = "@@study/WATCH_STUDY",
  FETCH_STUDY_RES_CONFIG = "@@study/FETCH_STUDY_RES_CONFIG",
  FETCH_STUDY_RES_CONFIG_SUCCESS = "@@study/FETCH_STUDY_RES_CONFIG_SUCCESS",
  WATCH_STUDY_RES_CONFIG = "@@study/WATCH_STUDY_RES_CONFIG",
  FETCH_STUDY_COURSES = "@@study/FETCH_STUDY_COURSES",
  FETCH_STUDY_COURSES_SUCCESS = "@@study/FETCH_STUDY_COURSES_SUCCESS",
  WATCH_STUDY_COURSES = "@@study/WATCH_STUDY_COURSES",
  FETCH_SCORING_COURSE_DETAILS = "@@study/FETCH_SCORING_COURSE_DETAILS",
  FETCH_SCORING_COURSE_DETAILS_SUCCESS = "@@study/FETCH_SCORING_COURSE_DETAILS_SUCCESS",
  WATCH_SCORING_COURSE_DETAILS = "@@study/WATCH_SCORING_COURSE_DETAILS",
  FETCH_SCORING_COURSE_DETAILS_LEGACY = "@@study/FETCH_SCORING_COURSE_DETAILS_LEGACY",
  FETCH_SCORING_COURSE_DETAILS_LEGACY_SUCCESS = "@@study/FETCH_SCORING_COURSE_DETAILS_LEGACY_SUCCESS",
  WATCH_SCORING_COURSE_DETAILS_LEGACY = "@@study/WATCH_SCORING_COURSE_DETAILS_LEGACY",
  FETCH_COURSE_THRESHOLDS = "@@study/FETCH_COURSE_THRESHOLDS",
  FETCH_COURSE_THRESHOLDS_SUCCESS = "@@study/FETCH_COURSE_THRESHOLDS_SUCCESS",
  WATCH_COURSE_THRESHOLDS_LEGACY = "@@study/WATCH_COURSE_THRESHOLDS_LEGACY",
  FETCH_COURSE_THRESHOLDS_LEGACY = "@@study/FETCH_COURSE_THRESHOLDS_LEGACY",
  FETCH_COURSE_THRESHOLDS_LEGACY_SUCCESS = "@@study/FETCH_COURSE_THRESHOLDS_LEGACY_SUCCESS",
  WATCH_COURSE_THRESHOLDS = "@@study/WATCH_COURSE_THRESHOLDS",
  SAVE_COURSE_THRESHOLDS = "@@study/SAVE_COURSE_THRESHOLDS",
  SAVE_COURSE_THRESHOLDS_SUCCESS = "@@study/SAVE_COURSE_THRESHOLDS_SUCCESS",
  WATCH_SAVE_COURSE_THRESHOLDS = "@@study/WATCH_SAVE_COURSE_THRESHOLDS",
  FETCH_COURSE_MASTER_QUESTIONS = "@@study/FETCH_COURSE_MASTER_QUESTIONS",
  FETCH_COURSE_MASTER_QUESTIONS_SUCCESS = "@@study/FETCH_COURSE_MASTER_QUESTIONS_SUCCESS",
  WATCH_FETCH_COURSE_MASTER_QUESTIONS = "@@study/WATCH_FETCH_COURSE_MASTER_QUESTIONS",
  FETCH_COURSE_MASTER_QUESTIONS_LEGACY = "@@study/FETCH_COURSE_MASTER_QUESTIONS_LEGACY",
  FETCH_COURSE_MASTER_QUESTIONS_LEGACY_SUCCESS = "@@study/FETCH_COURSE_MASTER_QUESTIONS_LEGACY_SUCCESS",
  WATCH_FETCH_COURSE_MASTER_QUESTIONS_LEGACY = "@@study/WATCH_FETCH_COURSE_MASTER_QUESTIONS_LEGACY",
  FETCH_COURSE_COUNTRY_QUESTIONS = "@@study/FETCH_COURSE_COUNTRY_QUESTIONS",
  FETCH_COURSE_COUNTRY_QUESTIONS_SUCCESS = "@@study/FETCH_COURSE_COUNTRY_QUESTIONS_SUCCESS",
  WATCH_FETCH_COURSE_COUNTRY_QUESTIONS = "@@study/WATCH_FETCH_COURSE_COUNTRY_QUESTIONS",
  FETCH_COURSE_COUNTRY_QUESTIONS_LEGACY = "@@study/FETCH_COURSE_COUNTRY_QUESTIONS_LEGACY",
  FETCH_COURSE_COUNTRY_QUESTIONS_LEGACY_SUCCESS = "@@study/FETCH_COURSE_COUNTRY_QUESTIONS_LEGACY_SUCCESS",
  WATCH_FETCH_COURSE_COUNTRY_QUESTIONS_LEGACY = "@@study/WATCH_FETCH_COURSE_COUNTRY_QUESTIONS_LEGACY",
  SAVE_COURSE_MASTER_QUESTIONS = "@@study/SAVE_COURSE_MASTER_QUESTIONS",
  SAVE_COURSE_MASTER_QUESTIONS_SUCCESS = "@@study/SAVE_COURSE_MASTER_QUESTIONS_SUCCESS",
  WATCH_SAVE_COURSE_MASTER_QUESTIONS = "@@study/WATCH_SAVE_COURSE_MASTER_QUESTIONS",
  SAVE_COURSE_COUNTRY_QUESTIONS = "@@study/SAVE_COURSE_COUNTRY_QUESTIONS",
  SAVE_COURSE_COUNTRY_QUESTIONS_SUCCESS = "@@study/SAVE_COURSE_COUNTRY_QUESTIONS_SUCCESS",
  WATCH_SAVE_COURSE_COUNTRY_QUESTIONS = "@@study/WATCH_SAVE_COURSE_COUNTRY_QUESTIONS",
  LOCK_COURSE_MASTER_QUESTIONS = "@@study/LOCK_COURSE_MASTER_QUESTIONS",
  LOCK_COURSE_MASTER_QUESTIONS_SUCCESS = "@@study/LOCK_COURSE_MASTER_QUESTIONS_SUCCESS",
  WATCH_LOCK_COURSE_MASTER_QUESTIONS = "@@study/WATCH_LOCK_COURSE_MASTER_QUESTIONS",
  FETCH_STUDY_PERSONNEL_TRAINING_SCORES = "@@study/FETCH_STUDY_PERSONNEL_TRAINING_SCORES",
  FETCH_STUDY_PERSONNEL_TRAINING_SCORES_SUCCESS = "@@study/FETCH_STUDY_PERSONNEL_TRAINING_SCORES_SUCCESS",
  WATCH_STUDY_PERSONNEL_TRAINING_SCORES = "@@study/WATCH_STUDY_PERSONNEL_TRAINING_SCORES",
  MODIFY_STUDY = "@@study/MODIFY_STUDY",
  MODIFY_STUDY_SUCCESS = "@@study/MODIFY_STUDY_SUCCESS",
  WATCH_MODIFY_STUDY = "@@study/WATCH_MODIFY_STUDY",
  WATCH_CLINICAL_DATA_STUDY = "@@study/WATCH_CLINICAL_DATA_STUDY",
  FETCH_CLINICAL_DATA = "@@study/FETCH_CLINICAL_DATA",
  FETCH_CLINICAL_DATA_SUCCESS = "@@study/FETCH_CLINICAL_DATA_SUCCESS",
  WATCH_CLINICAL_DATA = "@@study/WATCH_CLINICAL_DATA_STUDY",
  SAVE_CLINICAL_DATA_STUDY = "@study/SAVE_CLINICAL_DATA_STUDY",
  SAVE_CLINICAL_DATA_STUDY_SUCCESS = "@study/SAVE_CLINICAL_DATA_STUDY_SUCCESS",
  WATCH_SAVE_CLINICAL_DATA_STUDY = "@study/WATCH_SAVE_CLINICAL_DATA_STUDY",
  FETCH_STUDY_LOOKUPS = "@@study/FETCH_STUDY_LOOKUPS",
  FETCH_STUDY_LOOKUPS_SUCCESS = "@@study/FETCH_STUDY_LOOKUPS_SUCCESS",
  WATCH_STUDY_LOOKUPS = "@@study/WATCH_STUDY_LOOKUPS",
  FETCH_STUDY_PROTOCOLS = "@@study/FETCH_STUDY_PROTOCOLS",
  FETCH_STUDY_PROTOCOLS_SUCCESS = "@@study/FETCH_STUDY_PROTOCOLS_SUCCESS",
  WATCH_STUDY_PROTOCOLS = "@@study/WATCH_STUDY_PROTOCOLS",
  MODIFY_STUDY_PROTOCOL = "@@study/MODIFY_STUDY_PROTOCOL",
  MODIFY_STUDY_PROTOCOL_SUCCESS = "@@sponsor/MODIFY_STUDY_PROTOCOL_SUCCESS",
  WATCH_STUDY_PROTOCOL_SAVE = "@@sponsor/WATCH_STUDY_PROTOCOL_SAVE",
  FETCH_STUDY_SITES = "@@study/FETCH_STUDY_SITES",
  FETCH_STUDY_SITES_SUCCESS = "@@study/FETCH_STUDY_SITES_SUCCESS",
  WATCH_STUDY_SITES = "@@study/WATCH_STUDY_SITES",
  SAVE_STUDY_SITE = "@study/SAVE_STUDY_SITE",
  SAVE_STUDY_SITE_SUCCESS = "@study/SAVE_STUDY_SITE_SUCCESS",
  WATCH_SAVE_STUDY_SITE = "@@study/WATCH_SAVE_STUDY_SITE",
  SAVE_STUDY_SITE_PERSONNEL = "@study/SAVE_STUDY_SITE_PERSONNEL",
  SAVE_STUDY_SITE_PERSONNEL_SUCCESS = "@study/SAVE_STUDY_SITE_PERSONNEL_SUCCESS",
  WATCH_SAVE_STUDY_SITE_PERSONNEL = "@@study/SAVE_STUDY_SITE_PERSONNEL",
  UPDATE_STUDY_SITE_PERSON = "@study/UPDATE_STUDY_SITE_PERSON",
  UPDATE_STUDY_SITE_PERSON_SUCCESS = "@study/UPDATE_STUDY_SITE_PERSON_SUCCESS",
  WATCH_UPDATE_STUDY_SITE_PERSON = "@@study/WATCH_UPDATE_STUDY_SITE_PERSON",
  DELETE_STUDY_SITE = "@study/DELETE_STUDY_SITE",
  DELETE_STUDY_SITE_SUCCESS = "@study/DELETE_STUDY_SITE_SUCCESS",
  WATCH_DELETE_STUDY_SITE = "@@study/DELETE_STUDY_SITE",
  DELETE_STUDY_SCALE = "@study/DELETE_STUDY_SCALE",
  DELETE_STUDY_SCALE_SUCCESS = "@study/DELETE_STUDY_SCALE_SUCCESS",
  WATCH_DELETE_STUDY_SCALE = "@@study/DELETE_STUDY_SCALE",
  FETCH_STUDY_SCALES = "@@study/FETCH_STUDY_SCALES",
  FETCH_STUDY_SCALES_SUCCESS = "@@study/FETCH_STUDY_SCALES_SUCCESS",
  WATCH_STUDY_SCALES = "@@study/WATCH_STUDY_SCALES",
  FETCH_STUDY_SITE = "@@study/FETCH_STUDY_SITE",
  FETCH_STUDY_SITE_SUCCESS = "@@study/FETCH_STUDY_SITE_SUCCESS",
  WATCH_STUDY_SITE = "@@study/WATCH_STUDY_SITE",
  FETCH_SCALES = "FETCH_SCALES",
  SAVE_STUDY_SCALE = "@@study/SAVE_STUDY_SCALE",
  SAVE_STUDY_SCALE_SUCCESS = "@@study/SAVE_STUDY_SCALE_SUCCESS",
  WATCH_STUDY_SCALE = "@@study/WATCH_STUDY_SCALE",
  FETCH_STUDY_SITE_PERSONNEL = "@@study/FETCH_STUDY_SITE_PERSONNEL",
  FETCH_STUDY_SITE_PERSONNEL_SUCCESS = "@@study/FETCH_STUDY_SITE_PERSONNEL_SUCCESS",
  WATCH_STUDY_SITE_PERSONNEL = "@@study/WATCH_STUDY_SITE_PERSONNEL",
  FETCH_STUDY_SITE_PERSON = "@@study/FETCH_STUDY_SITE_PERSON",
  FETCH_STUDY_SITE_PERSON_SUCCESS = "@@study/FETCH_STUDY_SITE_PERSON_SUCCESS",
  WATCH_STUDY_SITE_PERSON = "@@study/WATCH_STUDY_SITE_PERSON",
  FETCH_STUDY_SITE_PERSON_BY_KEY = "@@study/FETCH_STUDY_SITE_PERSON_BY_KEY",
  FETCH_STUDY_SITE_PERSON_BY_KEY_SUCCESS = "@@study/FETCH_STUDY_SITE_PERSON_SUCCESS_BY_KEY",
  WATCH_STUDY_SITE_PERSON_BY_KEY = "@@study/WATCH_STUDY_SITE_PERSON_BY_KEY",
  DELETE_STUDY_SITE_PERSONNEL = "@@study/DELETE_STUDY_SITE_PERSONNEL",
  DELETE_STUDY_SITE_PERSONNEL_SUCCESS = "@@study/DELETE_STUDY_SITE_PERSONNEL_SUCCESS",
  WATCH_DELETE_STUDY_SITE_PERSONNEL = "@@study/WATCH_DELETE_STUDY_SITE_PERSONNEL",
  FETCH_PERSONNEL_IN_SITE = "@@study/FETCH_PERSONNEL_IN_SITE",
  FETCH_PERSONNEL_IN_SITE_SUCCESS = "@@study/FETCH_PERSONNEL_IN_SITE_SUCCESS",
  WATCH_PERSONNEL_IN_SITE = "@@study/WATCH_PERSONNEL_IN_SITE",
  FETCH_BULK_LP_DATA = "@@study/FETCH_BULK_LP_DATA",
  FETCH_BULK_LP_DATA_SUCCESS = "@@study/FETCH_BULK_LP_DATA_SUCCESS",
  WATCH_BULK_LP_DATA = "@@study/WATCH_BULK_LP_DATA",
  SAVE_BULK_LP_ASSIGNMENT = "@@study/SAVE_BULK_LP_ASSIGNMENT",
  SAVE_BULK_LP_ASSIGNMENT_SUCCESS = "@@study/SAVE_BULK_LP_ASSIGNMENT_SUCCESS",
  WATCH_BULK_LP_ASSIGNMENT = "@@study/WATCH_BULK_LP_ASSIGNMENT",
  // Study Site Person Experience
  FETCH_STUDY_SITE_PERSON_EXPERIENCE = "@@study/FETCH_STUDY_SITE_PERSON_EXPERIENCE",
  FETCH_STUDY_SITE_PERSON_EXPERIENCE_SUCCESS = "@@study/FETCH_STUDY_SITE_PERSON_EXPERIENCE_SUCCESS",
  WATCH_STUDY_SITE_PERSON_EXPERIENCE = "@@study/WATCH_STUDY_SITE_PERSON_EXPERIENCE",
  FETCH_STUDY_SITE_PERSON_SCALE_EXPERIENCE = "@@study/FETCH_STUDY_SITE_PERSON_SCALE_EXPERIENCE",
  FETCH_STUDY_SITE_PERSON_SCALE_EXPERIENCE_SUCCESS = "@@study/FETCH_STUDY_SITE_PERSON_SCALE_EXPERIENCE_SUCCESS",
  WATCH_STUDY_SITE_PERSON_SCALE_EXPERIENCE = "@@study/WATCH_STUDY_SITE_PERSON_SCALE_EXPERIENCE",
  FETCH_SCALE_EXPERIENCE_NEXT_AVAILABLE_STATUSES = "@@study/FETCH_SCALE_EXPERIENCE_NEXT_AVAILABLE_STATUSES",
  FETCH_SCALE_EXPERIENCE_NEXT_AVAILABLE_STATUSES_SUCCESS = "@@study/FETCH_SCALE_EXPERIENCE_NEXT_AVAILABLE_STATUSES_SUCCESS",
  WATCH_SCALE_EXPERIENCE_NEXT_AVAILABLE_STATUSES = "@@study/WATCH_SCALE_EXPERIENCE_NEXT_AVAILABLE_STATUSES",
  SAVE_SCALE_EXPERIENCE_STATUS = "@@study/SAVE_SCALE_EXPERIENCE_STATUS",
  SAVE_SCALE_EXPERIENCE_STATUS_SUCCESS = "@@study/SAVE_SCALE_EXPERIENCE_STATUS_SUCCESS",
  WATCH_SAVE_SCALE_EXPERIENCE_STATUS = "@@study/WATCH_SAVE_SCALE_EXPERIENCE_STATUS",
  SAVE_COURSE_STATUS = "@@study/SAVE_COURSE_STATUS",
  SAVE_COURSE_STATUS_SUCCESS = "@@study/SAVE_COURSE_STATUS_SUCCESS",
  WATCH_SAVE_COURSE_STATUS = "@@study/WATCH_SAVE_COURSE_STATUS",
  // Study Site Person Training
  FETCH_STUDY_SITE_PERSON_TRAINING = "@@study/FETCH_STUDY_SITE_PERSON_SCALE_TRAINING",
  FETCH_STUDY_SITE_PERSON_TRAINING_SUCCESS = "@@study/FETCH_STUDY_SITE_PERSON_SCALE_TRAINING_SUCCESS",
  WATCH_STUDY_SITE_PERSON_TRAINING = "@@study/WATCH_STUDY_SITE_PERSON_SCALE_TRAINING",
  SAVE_STUDY_SITE_PERSON_LP_ASSIGNMENT = "@@study/SAVE_STUDY_SITE_PERSON_LP_ASSIGNMENT",
  SAVE_STUDY_SITE_PERSON_LP_ASSIGNMENT_SUCCESS = "@@study/SAVE_STUDY_SITE_PERSON_LP_ASSIGNMENT_SUCCESS",
  WATCH_SAVE_STUDY_SITE_PERSON_LP_ASSIGNMENT = "@@study/WATCH_SAVE_STUDY_SITE_PERSON_LP_ASSIGNMENT",
  UNASSIGN_LP = "@@study/UNASSIGN_LP",
  UNASSIGN_LP_SUCCESS = "@@study/UNASSIGN_LP_SUCCESS",
  WATCH_UNASSIGN_LP = "@@study/WATCH_UNASSIGN_LP",
  FETCH_QUALIFICATION_NEXT_AVAILABLE_STATUSES = "@@study/FETCH_QUALIFICATION_NEXT_AVAILABLE_STATUSES",
  FETCH_QUALIFICATION_NEXT_AVAILABLE_STATUSES_SUCCESS = "@@study/FETCH_QUALIFICATION_NEXT_AVAILABLE_STATUSES_SUCCESS",
  WATCH_QUALIFICATION_NEXT_AVAILABLE_STATUSES = "@@study/WATCH_QUALIFICATION_NEXT_AVAILABLE_STATUSES",
  SAVE_QUALIFICATION_STATUS = "@@study/SAVE_QUALIFICATION_STATUS",
  SAVE_QUALIFICATION_STATUS_SUCCESS = "@@study/SAVE_QUALIFICATION_STATUS_SUCCESS",
  WATCH_SAVE_QUALIFICATION_STATUS = "@@study/WATCH_SAVE_QUALIFICATION_STATUS",
  SAVE_TRAINING_COURSE_MANUAL_SCORES = "@@study/SAVE_TRAINING_COURSE_MANUAL_SCORES",
  SAVE_TRAINING_COURSE_MANUAL_SCORES_SUCCESS = "@@study/SAVE_TRAINING_COURSE_MANUAL_SCORES_SUCCESS",
  WATCH_SAVE_TRAINING_COURSE_MANUAL_SCORES = "@@study/WATCH_SAVE_TRAINING_COURSE_MANUAL_SCORES",
  FETCH_TRAINING_COURSE_MANUAL_SCORES = "@@study/FETCH_TRAINING_COURSE_MANUAL_SCORES",
  FETCH_TRAINING_COURSE_MANUAL_SCORES_SUCCESS = "@@study/FETCH_TRAINING_COURSE_MANUAL_SCORES_SUCCESS",
  WATCH_FETCH_TRAINING_COURSE_MANUAL_SCORES = "@@study/WATCH_FETCH_TRAINING_COURSE_MANUAL_SCORES",
  DELETE_TRAINING_COURSE_MANUAL_SCORES = "@@study/DELETE_TRAINING_COURSE_MANUAL_SCORES",
  DELETE_TRAINING_COURSE_MANUAL_SCORES_SUCCESS = "@@study/DELETE_TRAINING_COURSE_MANUAL_SCORES_SUCCESS",
  WATCH_DELETE_TRAINING_COURSE_MANUAL_SCORES = "@@study/WATCH_DELETE_TRAINING_COURSE_MANUAL_SCORES",

  // Comments
  SAVE_STUDY_SITE_PERSON_COMMENT = "@study/SAVE_STUDY_SITE_PERSON_COMMENT",
  SAVE_STUDY_SITE_PERSON_COMMENT_SUCCESS = "@study/SAVE_STUDY_SITE_PERSON_COMMENT_SUCCESS",
  WATCH_SAVE_STUDY_SITE_PERSON_COMMENT = "@@study/WATCH_SAVE_STUDY_SITE_PERSON_COMMENT",
  FETCH_STUDY_SITE_PERSON_COMMENTS = "@study/FETCH_STUDY_SITE_PERSON_COMMENTS",
  FETCH_STUDY_SITE_PERSON_COMMENTS_SUCCESS = "@study/FETCH_STUDY_SITE_PERSON_COMMENTS_SUCCESS",
  WATCH_FETCH_STUDY_SITE_PERSON_COMMENTS = "@@study/WATCH_FETCH_STUDY_SITE_PERSON_COMMENTS",
  UPDATE_STUDY_SITE_PERSON_COMMENT = "@study/UPDATE_STUDY_SITE_PERSON_COMMENT",
  UPDATE_STUDY_SITE_PERSON_COMMENT_SUCCESS = "@study/UPDATE_STUDY_SITE_PERSON_COMMENT_SUCCESS",
  WATCH_UPDATE_STUDY_SITE_PERSON_COMMENT = "@@study/WATCH_UPDATE_STUDY_SITE_PERSON_COMMENT",
  // Qualifications
  FETCH_STUDY_QUALIFICATIONS = "@@study/FETCH_STUDY_QUALIFICATIONS",
  FETCH_STUDY_QUALIFICATIONS_SUCCESS = "@@study/FETCH_STUDY_QUALIFICATIONS_SUCCESS",
  WATCH_STUDY_QUALIFICATIONS = "@@study/WATCH_STUDY_QUALIFICATIONS",
  SAVE_STUDY_QUALIFICATION = "@@study/SAVE_STUDY_QUALIFICATION",
  SAVE_STUDY_QUALIFICATION_SUCCESS = "@@study/SAVE_STUDY_QUALIFICATION_SUCCESS",
  WATCH_STUDY_QUALIFICATION = "@@study/WATCH_SAVE_STUDY_QUALIFICATION",
  DELETE_STUDY_QUALIFICATION = "@@study/DELETE_STUDY_QUALIFICATION",
  DELETE_STUDY_QUALIFICATION_SUCCESS = "@@study/DELETE_STUDY_QUALIFICATION_SUCCESS",
  WATCH_DELETE_STUDY_QUALIFICATION = "@@study/WATCH_DELETE_STUDY_QUALIFICATION",
  FETCH_STUDY_QUALIFICATION = "@@study/FETCH_STUDY_QUALIFICATION",
  FETCH_STUDY_QUALIFICATION_SUCCESS = "@@study/FETCH_STUDY_QUALIFICATION_SUCCESS",
  WATCH_FETCH_STUDY_QUALIFICATION = "@@study/WATCH_FETCH_STUDY_QUALIFICATION",

  // Learning Plans
  FETCH_LEARNING_PLANS = "@@study/FETCH_LEARNING_PLANS",
  FETCH_LEARNING_PLANS_SUCCESS = "@@study/FETCH_LEARNING_PLANS_SUCCESS",
  WATCH_LEARNING_PLANS = "@@study/WATCH_LEARNING_PLANS",
  FETCH_LEARNING_PLANS_BULK_ASSIGN = "@@study/FETCH_LEARNING_PLANS_BULK_ASSIGN",
  FETCH_LEARNING_PLANS_BULK_ASSIGN_SUCCESS = "@@study/FETCH_LEARNING_PLANS_BULK_ASSIGN_SUCCESS",
  WATCH_FETCH_LEARNING_PLANS_BULK_ASSIGN = "@@study/WATCH_LEARNING_PLANS_BULK_ASSIGN",

  WATCH_DEMOGRAPHIC_TYPES = "@@study/WATCH_DEMOGRAPHIC_TYPES",
  FETCH_DEMOGRAPHIC_TYPES = "@@study/FETCH_DEMOGRAPHICS_TYPES",
  FETCH_DEMOGRAPHIC_TYPES_SUCCESS = "@@study/FETCH_DEMOGRAPHIC_TYPES_SUCCESS"
}

// Fetch studies
initSaga(
  function* fetchStudies(action: IAction) {
    const studies = yield call(StudyService.getStudies, action.payload);
    yield put({ type: eStudySaga.FETCH_STUDIES_SUCCESS, payload: studies });
  },
  eStudySaga.FETCH_STUDIES,
  eStudySaga.WATCH_STUDIES
);

initSaga(
  function* fetchStudyCourses(action: IAction) {
    const courses = yield call(StudyService.getStudyCourses, action.payload);
    yield put({
      type: eStudySaga.FETCH_STUDY_COURSES_SUCCESS,
      payload: courses
    });
  },
  eStudySaga.FETCH_STUDY_COURSES,
  eStudySaga.WATCH_STUDY_COURSES
);

initSaga(
  function* fetchCountryScoresDetails(action: IAction) {
    const details = yield call(
      StudyService.getScoringCourseDetails,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_SCORING_COURSE_DETAILS_SUCCESS,
      payload: details
    });
  },
  eStudySaga.FETCH_SCORING_COURSE_DETAILS,
  eStudySaga.WATCH_SCORING_COURSE_DETAILS
);
initSaga(
  function* fetchScoringCourseDetailsLegacy(action: IAction) {
    const details = yield call(
      StudyService.getScoringCourseDetailsLegacy,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_SCORING_COURSE_DETAILS_LEGACY_SUCCESS,
      payload: details
    });
  },
  eStudySaga.FETCH_SCORING_COURSE_DETAILS_LEGACY,
  eStudySaga.WATCH_SCORING_COURSE_DETAILS_LEGACY
);

initSaga(
  function* (action: IAction) {
    yield SagaService.dispatchSaga({ type: eUtilSaga.LOADING, payload: true });
    const studies = yield call(StudyService.getStudy, action.payload);
    yield put({ type: eStudySaga.FETCH_STUDY_SUCCESS, payload: studies });
  },
  eStudySaga.FETCH_STUDY,
  eStudySaga.WATCH_STUDY
);

initSaga(
  function* (action: IAction) {
    const resConfig = yield call(
      StudyService.getStudyResConfiguration,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_STUDY_RES_CONFIG_SUCCESS,
      payload: resConfig
    });
  },
  eStudySaga.FETCH_STUDY_RES_CONFIG,
  eStudySaga.WATCH_STUDY_RES_CONFIG
);

initSaga(
  function* (action: IAction) {
    const study = yield call(StudyService.modifyStudy, action.payload);
    yield put({ type: eStudySaga.MODIFY_STUDY_SUCCESS, payload: study });
    yield history.push(`/study/${study.studyId || action.payload.studyId}`);
    yield store.dispatch(openSuccessSnackBar("Save successful"));
  },
  eStudySaga.MODIFY_STUDY,
  eStudySaga.WATCH_MODIFY_STUDY
);

export function* fetchstudyLookups(action: IAction) {
  try {
    yield SagaService.dispatchSaga({ type: eUtilSaga.LOADING, payload: true });
    const { therapeuticAreas, indications, demographics, specialities } =
      yield all({
        therapeuticAreas: call(LookupService.getTherapeuticAreas),
        indications: call(LookupService.getIndications),
        demographics: call(LookupService.getDemographics),
        specialities: call(LookupService.getSpecialities)
      });

    yield put({
      type: eStudySaga.FETCH_STUDY_LOOKUPS_SUCCESS,
      payload: { therapeuticAreas, indications, demographics, specialities }
    });
  } catch (e) {
    console.log(e);
  } finally {
    yield SagaService.dispatchSaga({ type: eUtilSaga.LOADING, payload: false });
  }
}

export function* watchFetchstudyLookups() {
  yield takeLatest(
    eStudySaga.FETCH_STUDY_LOOKUPS.toString(),
    fetchstudyLookups
  );
}

initSaga(
  function* (action: IAction) {
    const clinicalStudyData = yield call(
      StudyService.fetchClinicalStudyData,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_CLINICAL_DATA_SUCCESS,
      payload: clinicalStudyData
    });
  },
  eStudySaga.FETCH_CLINICAL_DATA,
  eStudySaga.WATCH_CLINICAL_DATA
);

initSaga(
  function* (action: IAction) {
    const clinicalStudyData = yield call(
      StudyService.saveClinicalStudyData,
      action.payload
    );
    yield put({
      type: eStudySaga.SAVE_CLINICAL_DATA_STUDY_SUCCESS,
      payload: clinicalStudyData
    });
    yield store.dispatch(openSuccessSnackBar("Save successful"));
  },
  eStudySaga.SAVE_CLINICAL_DATA_STUDY,
  eStudySaga.WATCH_SAVE_CLINICAL_DATA_STUDY
);

initSaga(
  function* fetchStudyProtocolsSaga(action: IAction) {
    const studyProtocolsData = yield call(
      StudyService.fetchStudyProtocolsData,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_STUDY_PROTOCOLS_SUCCESS,
      payload: studyProtocolsData
    });
  },
  eStudySaga.FETCH_STUDY_PROTOCOLS,
  eStudySaga.WATCH_STUDY_PROTOCOLS
);

initSaga(
  function* modifyStudyProtocolsSaga(action: IAction) {
    const studyProtocol = yield call(
      StudyService.saveStudyProtocol,
      action.payload
    );
    yield put({
      type: eStudySaga.MODIFY_STUDY_PROTOCOL_SUCCESS,
      payload: studyProtocol
    });
    yield history.push(`/study/${studyProtocol.studyId}/protocol`);
    yield store.dispatch(openSuccessSnackBar("Save successful"));
  },
  eStudySaga.MODIFY_STUDY_PROTOCOL,
  eStudySaga.WATCH_STUDY_PROTOCOL_SAVE
);

initSaga(
  function* deleteStudySiteSaga(action: IAction) {
    let studySite = yield call(StudyService.deleteStudySite, action.payload);
    if (studySite) {
      studySite = action.payload;
    }
    yield put({
      type: eStudySaga.DELETE_STUDY_SITE_SUCCESS,
      payload: studySite
    });
    yield history.push(`/study/${studySite.studyId}/sites`);
    yield store.dispatch(openSuccessSnackBar("Delete successful"));
  },
  eStudySaga.DELETE_STUDY_SITE,
  eStudySaga.WATCH_DELETE_STUDY_SITE
);

initSaga(
  function* deleteStudyScaleSaga(action: IAction) {
    let studyScale = yield call(StudyService.deleteStudyScale, action.payload);
    if (studyScale) {
      studyScale = action.payload;
    }
    yield put({
      type: eStudySaga.DELETE_STUDY_SCALE_SUCCESS,
      payload: studyScale
    });
    yield history.push(`/study/${studyScale.studyId}/scales`);
    yield store.dispatch(openSuccessSnackBar("Delete successful"));
  },
  eStudySaga.DELETE_STUDY_SCALE,
  eStudySaga.WATCH_DELETE_STUDY_SCALE
);

// Fetch Study Sites
initSaga(
  function* (action: IAction) {
    const studySites = yield call(StudyService.getStudySites, action.payload);
    yield put({
      type: eStudySaga.FETCH_STUDY_SITES_SUCCESS,
      payload: studySites
    });
  },
  eStudySaga.FETCH_STUDY_SITES,
  eStudySaga.WATCH_STUDY_SITES
);

initSaga(
  function* saveStudySiteSaga(action: IAction) {
    const studySite = yield call(StudyService.saveStudySite, action.payload);
    yield put({ type: eStudySaga.SAVE_STUDY_SITE_SUCCESS, payload: studySite });
    yield history.push(`/study/${studySite.studyId}/sites/${studySite.siteId}`);
    yield store.dispatch(openSuccessSnackBar("Save successful"));
  },
  eStudySaga.SAVE_STUDY_SITE,
  eStudySaga.WATCH_SAVE_STUDY_SITE
);

initSaga(
  function* (action: IAction) {
    const studySitePerson = yield call(
      StudyService.saveStudySitePersonnel,
      action.payload
    );
    yield put({
      type: eStudySaga.SAVE_STUDY_SITE_PERSONNEL_SUCCESS,
      payload: studySitePerson
    });
    yield store.dispatch(openSuccessSnackBar("Save successful"));
  },
  eStudySaga.SAVE_STUDY_SITE_PERSONNEL,
  eStudySaga.WATCH_SAVE_STUDY_SITE_PERSONNEL
);

initSaga(
  function* (action: IAction) {
    const studySitePerson = yield call(
      StudyService.updateStudySitePerson,
      action.payload
    );
    yield put({
      type: eStudySaga.UPDATE_STUDY_SITE_PERSON_SUCCESS,
      payload: studySitePerson
    });
    yield store.dispatch(openSuccessSnackBar("Save successful"));
  },
  eStudySaga.UPDATE_STUDY_SITE_PERSON,
  eStudySaga.WATCH_UPDATE_STUDY_SITE_PERSON
);

initSaga(
  function* saveStudySitePersonComment(action: IAction) {
    const studySitePersonComment = yield call(
      StudyService.saveStudySitePersonComment,
      action.payload
    );
    yield put({
      type: eStudySaga.SAVE_STUDY_SITE_PERSON_COMMENT_SUCCESS,
      payload: studySitePersonComment
    });
    yield store.dispatch(openSuccessSnackBar("Save successful"));
  },
  eStudySaga.SAVE_STUDY_SITE_PERSON_COMMENT,
  eStudySaga.WATCH_SAVE_STUDY_SITE_PERSON_COMMENT
);

initSaga(
  function* updateStudySitePersonComment(action: IAction) {
    const studySitePersonComment = yield call(
      StudyService.updateStudySitePersonComment,
      action.payload
    );
    yield put({
      type: eStudySaga.UPDATE_STUDY_SITE_PERSON_COMMENT_SUCCESS,
      payload: studySitePersonComment
    });
    yield store.dispatch(openSuccessSnackBar("Save successful"));
  },
  eStudySaga.UPDATE_STUDY_SITE_PERSON_COMMENT,
  eStudySaga.WATCH_UPDATE_STUDY_SITE_PERSON_COMMENT
);

initSaga(
  function* fetchStudySitePersonComments(action: IAction) {
    const comments = yield call(
      StudyService.getStudySitePersonComments,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_STUDY_SITE_PERSON_COMMENTS_SUCCESS,
      payload: comments
    });
  },
  eStudySaga.FETCH_STUDY_SITE_PERSON_COMMENTS,
  eStudySaga.WATCH_FETCH_STUDY_SITE_PERSON_COMMENTS
);

initSaga(
  function* fetchStudySitePersonExperience(action: IAction) {
    const experience = yield call(
      StudyService.getStudySitePersonExperience,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_STUDY_SITE_PERSON_EXPERIENCE_SUCCESS,
      payload: experience
    });
  },
  eStudySaga.FETCH_STUDY_SITE_PERSON_EXPERIENCE,
  eStudySaga.WATCH_STUDY_SITE_PERSON_EXPERIENCE
);
initSaga(
  function* fetchStudySitePersonScaleExperience(action: IAction) {
    const experience = yield call(
      StudyService.getStudySitePersonScaleExperience,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_STUDY_SITE_PERSON_SCALE_EXPERIENCE_SUCCESS,
      payload: experience
    });
  },
  eStudySaga.FETCH_STUDY_SITE_PERSON_SCALE_EXPERIENCE,
  eStudySaga.WATCH_STUDY_SITE_PERSON_SCALE_EXPERIENCE
);

initSaga(
  function* fetchScaleExperienceNextAvailableStatuses(action: IAction) {
    const statuses = yield call(
      StudyService.getScaleExperienceNextAvailableStatuses,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_SCALE_EXPERIENCE_NEXT_AVAILABLE_STATUSES_SUCCESS,
      payload: statuses
    });
  },
  eStudySaga.FETCH_SCALE_EXPERIENCE_NEXT_AVAILABLE_STATUSES,
  eStudySaga.WATCH_SCALE_EXPERIENCE_NEXT_AVAILABLE_STATUSES
);

initSaga(
  function* saveScaleExperienceStatus(action: IAction) {
    const result = yield call(
      StudyService.saveExperienceScaleStatus,
      action.payload
    );
    yield put({
      type: eStudySaga.SAVE_SCALE_EXPERIENCE_STATUS_SUCCESS,
      payload: result
    });
  },
  eStudySaga.SAVE_SCALE_EXPERIENCE_STATUS,
  eStudySaga.WATCH_SAVE_SCALE_EXPERIENCE_STATUS
);

initSaga(
  function* fetchStudySitePersonTraining(action: IAction) {
    const experience = yield call(
      StudyService.getStudySitePersonTraining,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_STUDY_SITE_PERSON_TRAINING_SUCCESS,
      payload: experience
    });
  },
  eStudySaga.FETCH_STUDY_SITE_PERSON_TRAINING,
  eStudySaga.WATCH_STUDY_SITE_PERSON_TRAINING
);

initSaga(
  function* fetchQualificationNextAvailableStatuses(action: IAction) {
    const statuses = yield call(
      StudyService.getQualificationNextAvailableStatuses,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_QUALIFICATION_NEXT_AVAILABLE_STATUSES_SUCCESS,
      payload: statuses
    });
  },
  eStudySaga.FETCH_QUALIFICATION_NEXT_AVAILABLE_STATUSES,
  eStudySaga.WATCH_QUALIFICATION_NEXT_AVAILABLE_STATUSES
);

initSaga(
  function* saveQualificationStatus(action: IAction) {
    const data = yield call(
      StudyService.saveQualificationStatus,
      action.payload
    );
    yield put({
      type: eStudySaga.SAVE_QUALIFICATION_STATUS_SUCCESS,
      payload: data
    });
    yield store.dispatch(openSuccessSnackBar("Status updated"));
  },
  eStudySaga.SAVE_QUALIFICATION_STATUS,
  eStudySaga.WATCH_SAVE_QUALIFICATION_STATUS
);

initSaga(
  function* saveTrainingCourseManualScores(action: IAction) {
    const data = yield call(
      StudyService.saveTrainingCourseScores,
      action.payload
    );
    yield put({
      type: eStudySaga.SAVE_TRAINING_COURSE_MANUAL_SCORES_SUCCESS,
      payload: data
    });
    yield store.dispatch(openSuccessSnackBar("Scores saved"));
  },
  eStudySaga.SAVE_TRAINING_COURSE_MANUAL_SCORES,
  eStudySaga.WATCH_SAVE_TRAINING_COURSE_MANUAL_SCORES
);

initSaga(
  function* getTrainingCourseManualScores(action: IAction) {
    const data = yield call(
      StudyService.getTrainingCourseScores,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_TRAINING_COURSE_MANUAL_SCORES_SUCCESS,
      payload: data
    });
  },
  eStudySaga.FETCH_TRAINING_COURSE_MANUAL_SCORES,
  eStudySaga.WATCH_FETCH_TRAINING_COURSE_MANUAL_SCORES
);

initSaga(
  function* deleteTrainingCourseManualScores(action: IAction) {
    const data = yield call(
      StudyService.deleteTrainingCourseScores,
      action.payload
    );
    yield put({
      type: eStudySaga.DELETE_TRAINING_COURSE_MANUAL_SCORES_SUCCESS,
      payload: data
    });
    yield store.dispatch(openSuccessSnackBar("Delete successful"));
  },
  eStudySaga.DELETE_TRAINING_COURSE_MANUAL_SCORES,
  eStudySaga.WATCH_DELETE_TRAINING_COURSE_MANUAL_SCORES
);

initSaga(
  function* saveStudySitePersonLPAssignment(action: IAction) {
    const result = yield call(StudyService.saveLpAssignment, action.payload);
    yield put({
      type: eStudySaga.SAVE_STUDY_SITE_PERSON_LP_ASSIGNMENT_SUCCESS,
      payload: result
    });
    yield store.dispatch(openSuccessSnackBar("Assignment(s) successful"));
  },
  eStudySaga.SAVE_STUDY_SITE_PERSON_LP_ASSIGNMENT,
  eStudySaga.WATCH_SAVE_STUDY_SITE_PERSON_LP_ASSIGNMENT
);

initSaga(
  function* unassignLP(action: IAction) {
    const result = yield call(StudyService.unassignLP, action.payload);
    yield put({
      type: eStudySaga.UNASSIGN_LP_SUCCESS,
      payload: result
    });
    yield store.dispatch(openSuccessSnackBar("Unassign successful"));
  },
  eStudySaga.UNASSIGN_LP,
  eStudySaga.WATCH_UNASSIGN_LP
);

initSaga(
  function* saveCourseStatus(action: IAction) {
    const result = yield call(StudyService.saveCourseStatus, action.payload);
    yield put({
      type: eStudySaga.SAVE_COURSE_STATUS_SUCCESS,
      payload: result
    });
  },
  eStudySaga.SAVE_COURSE_STATUS,
  eStudySaga.WATCH_SAVE_COURSE_STATUS
);

initSaga(
  function* fetchCourseThresholds(action: IAction) {
    const thresholds = yield call(
      StudyService.getCourseThresholds,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_COURSE_THRESHOLDS_SUCCESS,
      payload: thresholds
    });
  },
  eStudySaga.FETCH_COURSE_THRESHOLDS,
  eStudySaga.WATCH_COURSE_THRESHOLDS
);

initSaga(
  function* fetchCourseThresholdsLegacy(action: IAction) {
    const thresholds = yield call(
      StudyService.getCourseThresholdsLegacy,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_COURSE_THRESHOLDS_LEGACY_SUCCESS,
      payload: thresholds
    });
  },
  eStudySaga.FETCH_COURSE_THRESHOLDS_LEGACY,
  eStudySaga.WATCH_COURSE_THRESHOLDS_LEGACY
);

initSaga(
  function* saveCourseThresholds(action: IAction) {
    const thresholdResponse = yield call(
      StudyService.saveCourseThresholds,
      action.payload
    );
    yield put({
      type: eStudySaga.SAVE_COURSE_THRESHOLDS_SUCCESS,
      payload: thresholdResponse
    });
    yield store.dispatch(
      openSuccessSnackBar(
        `Thresholds have been successfully ${
          action.payload.isLocked ? "locked" : "modified"
        }.`
      )
    );
  },
  eStudySaga.SAVE_COURSE_THRESHOLDS,
  eStudySaga.WATCH_SAVE_COURSE_THRESHOLDS
);

initSaga(
  function* fetchPersonnelTrainingScores(action: IAction) {
    const scores = yield call(
      StudyService.getPersonnelTrainingScores,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_STUDY_PERSONNEL_TRAINING_SCORES_SUCCESS,
      payload: scores
    });
  },
  eStudySaga.FETCH_STUDY_PERSONNEL_TRAINING_SCORES,
  eStudySaga.WATCH_STUDY_PERSONNEL_TRAINING_SCORES
);

initSaga(
  function* fetchCourseMasterQuestions(action: IAction) {
    const questions = yield call(
      StudyService.getCourseMasterQuestions,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_COURSE_MASTER_QUESTIONS_SUCCESS,
      payload: questions
    });
  },
  eStudySaga.FETCH_COURSE_MASTER_QUESTIONS,
  eStudySaga.WATCH_FETCH_COURSE_MASTER_QUESTIONS
);
initSaga(
  function* fetchCourseMasterQuestionsLegacy(action: IAction) {
    const questions = yield call(
      StudyService.getCourseMasterQuestionsLegacy,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_COURSE_MASTER_QUESTIONS_LEGACY_SUCCESS,
      payload: questions
    });
  },
  eStudySaga.FETCH_COURSE_MASTER_QUESTIONS_LEGACY,
  eStudySaga.WATCH_FETCH_COURSE_MASTER_QUESTIONS_LEGACY
);

initSaga(
  function* fetchCourseCountryQuestions(action: IAction) {
    const questions = yield call(
      StudyService.getCourseCountryQuestions,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_COURSE_COUNTRY_QUESTIONS_SUCCESS,
      payload: questions
    });
  },
  eStudySaga.FETCH_COURSE_COUNTRY_QUESTIONS,
  eStudySaga.WATCH_FETCH_COURSE_COUNTRY_QUESTIONS
);
initSaga(
  function* fetchCourseCountryQuestionsLegacy(action: IAction) {
    const questions = yield call(
      StudyService.getCourseCountryQuestionsLegacy,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_COURSE_COUNTRY_QUESTIONS_LEGACY_SUCCESS,
      payload: questions
    });
  },
  eStudySaga.FETCH_COURSE_COUNTRY_QUESTIONS_LEGACY,
  eStudySaga.WATCH_FETCH_COURSE_COUNTRY_QUESTIONS_LEGACY
);

initSaga(
  function* updateCourseMasterQuestions(action: IAction) {
    const questions = yield call(
      StudyService.updateCourseMasterQuestions,
      action.payload
    );
    yield put({
      type: eStudySaga.SAVE_COURSE_MASTER_QUESTIONS_SUCCESS,
      payload: questions
    });
    yield store.dispatch(
      openSuccessSnackBar("Changes have been successfully saved")
    );
  },
  eStudySaga.SAVE_COURSE_MASTER_QUESTIONS,
  eStudySaga.WATCH_SAVE_COURSE_MASTER_QUESTIONS
);

initSaga(
  function* updateCourseCountryQuestions(action: IAction) {
    const questions = yield call(
      StudyService.updateCourseCountryQuestions,
      action.payload
    );
    yield put({
      type: eStudySaga.SAVE_COURSE_COUNTRY_QUESTIONS_SUCCESS,
      payload: questions
    });
    yield store.dispatch(
      openSuccessSnackBar("Changes have been successfully saved")
    );
  },
  eStudySaga.SAVE_COURSE_COUNTRY_QUESTIONS,
  eStudySaga.WATCH_SAVE_COURSE_COUNTRY_QUESTIONS
);

initSaga(
  function* lockCourseMasterQuestions(action: IAction) {
    yield call(StudyService.lockCourseMasterQuestions, action.payload);
    yield put({
      type: eStudySaga.LOCK_COURSE_MASTER_QUESTIONS_SUCCESS,
      payload: {}
    });
    yield store.dispatch(
      openSuccessSnackBar("Master has been successfully locked")
    );
  },
  eStudySaga.LOCK_COURSE_MASTER_QUESTIONS,
  eStudySaga.WATCH_LOCK_COURSE_MASTER_QUESTIONS
);

initSaga(
  function* (action: IAction) {
    const studyScales = yield call(StudyService.getStudyScales, action.payload);
    yield put({
      type: eStudySaga.FETCH_STUDY_SCALES_SUCCESS,
      payload: studyScales
    });
  },
  eStudySaga.FETCH_STUDY_SCALES,
  eStudySaga.WATCH_STUDY_SCALES
);

initSaga(
  function* (action: IAction) {
    const learningPlans = yield call(
      StudyService.getLearningPlans,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_LEARNING_PLANS_SUCCESS,
      payload: learningPlans
    });
  },
  eStudySaga.FETCH_LEARNING_PLANS,
  eStudySaga.WATCH_LEARNING_PLANS
);

initSaga(
  function* fetchLearningPlansForBulkAssign(action: IAction) {
    const learningPlans = yield call(
      StudyService.getLearningPlansForBulkAssign,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_LEARNING_PLANS_BULK_ASSIGN_SUCCESS,
      payload: learningPlans
    });
  },
  eStudySaga.FETCH_LEARNING_PLANS_BULK_ASSIGN,
  eStudySaga.WATCH_FETCH_LEARNING_PLANS_BULK_ASSIGN
);

initSaga(
  function* fetchStudyQualification(action: IAction) {
    const qualification = yield call(
      StudyService.getStudyQualification,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_STUDY_QUALIFICATION_SUCCESS,
      payload: qualification
    });
  },
  eStudySaga.FETCH_STUDY_QUALIFICATION,
  eStudySaga.WATCH_FETCH_STUDY_QUALIFICATION
);

initSaga(
  function* fetchStudySiteSaga(action: IAction) {
    const studySite = yield call(StudyService.getStudySite, action.payload);
    yield put({
      type: eStudySaga.FETCH_STUDY_SITE_SUCCESS,
      payload: studySite
    });
  },
  eStudySaga.FETCH_STUDY_SITE,
  eStudySaga.WATCH_STUDY_SITE
);

initSaga(
  function* (action: IAction) {
    const studyScale = yield call(StudyService.saveStudyScale, action.payload);
    yield put({
      type: eStudySaga.SAVE_STUDY_SCALE_SUCCESS,
      payload: studyScale
    });
    yield store.dispatch(openSuccessSnackBar("Save successful"));
  },
  eStudySaga.SAVE_STUDY_SCALE,
  eStudySaga.WATCH_STUDY_SCALE
);

initSaga(
  function* (action: IAction) {
    const studySitePersonnel = yield call(
      StudyService.getStudySitePersonnel,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_STUDY_SITE_PERSONNEL_SUCCESS,
      payload: studySitePersonnel
    });
  },
  eStudySaga.FETCH_STUDY_SITE_PERSONNEL,
  eStudySaga.WATCH_STUDY_SITE_PERSONNEL
);

initSaga(
  function* (action: IAction) {
    const persons = yield call(
      StudyService.fetchPersonnelInSite,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_PERSONNEL_IN_SITE_SUCCESS,
      payload: persons
    });
  },
  eStudySaga.FETCH_PERSONNEL_IN_SITE,
  eStudySaga.WATCH_PERSONNEL_IN_SITE
);

initSaga(
  function* fetchBulkLPData(action: IAction) {
    const data = yield call(StudyService.fetchBulkLPData, action.payload);
    yield put({
      type: eStudySaga.FETCH_BULK_LP_DATA_SUCCESS,
      payload: data
    });
  },
  eStudySaga.FETCH_BULK_LP_DATA,
  eStudySaga.WATCH_BULK_LP_DATA
);

initSaga(
  function* saveBulkLPAssignment(action: IAction) {
    const data = yield call(StudyService.saveBulkLPAssignment, action.payload);
    yield put({
      type: eStudySaga.SAVE_BULK_LP_ASSIGNMENT_SUCCESS,
      payload: data
    });
    yield store.dispatch(openSuccessSnackBar("Assignment successful"));
  },
  eStudySaga.SAVE_BULK_LP_ASSIGNMENT,
  eStudySaga.WATCH_BULK_LP_ASSIGNMENT
);

initSaga(
  function* fetchStudySitePerson(action: IAction) {
    const studySitePerson = yield call(
      StudyService.fetchStudySitePerson,
      action.payload
    );

    store.dispatch({
      type: eStudyActionTypes.SET_STUDY_SITE_PERSON,
      payload: studySitePerson
    });
    yield put({
      type: eStudySaga.FETCH_STUDY_SITE_PERSON_SUCCESS,
      payload: studySitePerson
    });
  },
  eStudySaga.FETCH_STUDY_SITE_PERSON,
  eStudySaga.WATCH_STUDY_SITE_PERSON
);

initSaga(
  function* (action: IAction) {
    const studySitePerson = yield call(
      StudyService.fetchStudySitePersonByKey,
      action.payload
    );
    yield put({
      type: eStudySaga.FETCH_STUDY_SITE_PERSON_BY_KEY_SUCCESS,
      payload: studySitePerson
    });
  },
  eStudySaga.FETCH_STUDY_SITE_PERSON_BY_KEY,
  eStudySaga.WATCH_STUDY_SITE_PERSON_BY_KEY
);

initSaga(
  function* (action: IAction) {
    yield call(StudyService.deleteStudySitePersonnel, action.payload);
    yield put({ type: eStudySaga.DELETE_STUDY_SITE_PERSONNEL_SUCCESS });
    yield store.dispatch(openSuccessSnackBar("Delete successful"));
  },
  eStudySaga.DELETE_STUDY_SITE_PERSONNEL,
  eStudySaga.WATCH_DELETE_STUDY_SITE_PERSONNEL
);

initSaga(
  function* (action: IAction) {
    const quals = yield call(StudyService.getQualifications, action.payload);
    yield put({
      type: eStudySaga.FETCH_STUDY_QUALIFICATIONS_SUCCESS,
      payload: quals
    });
  },
  eStudySaga.FETCH_STUDY_QUALIFICATIONS,
  eStudySaga.WATCH_STUDY_QUALIFICATIONS
);

initSaga(
  function* (action: IAction) {
    yield call(StudyService.saveQualification, action.payload);
    yield put({ type: eStudySaga.SAVE_STUDY_QUALIFICATION_SUCCESS });
    yield store.dispatch(openSuccessSnackBar("Save successful"));
  },
  eStudySaga.SAVE_STUDY_QUALIFICATION,
  eStudySaga.WATCH_STUDY_QUALIFICATION
);

initSaga(
  function* (action: IAction) {
    yield call(StudyService.deleteQualification, action.payload);
    yield put({ type: eStudySaga.DELETE_STUDY_QUALIFICATION_SUCCESS });
    yield store.dispatch(openSuccessSnackBar("Delete successful"));
  },
  eStudySaga.DELETE_STUDY_QUALIFICATION,
  eStudySaga.WATCH_DELETE_STUDY_QUALIFICATION
);

initSaga(
  function* fetchDemographicTypes(action: IAction) {
    const demographics = yield call(StudyService.getDemographicTypes);
    yield put({
      type: eStudySaga.FETCH_DEMOGRAPHIC_TYPES_SUCCESS,
      payload: demographics
    });
  },
  eStudySaga.FETCH_DEMOGRAPHIC_TYPES,
  eStudySaga.WATCH_DEMOGRAPHIC_TYPES
);

export const overrideStudySagas = () => {
  overrideSaga(
    function* dispatchStudy() {
      const payload = Object.assign(new Study(), {
        studyId: "1",
        studyName: "TestStudy",
        sponsorName: "TestSponsor"
      });
      store.dispatch({ type: eStudyActionTypes.SET_STUDY, payload });
      yield put({ type: eStudySaga.FETCH_STUDY_SUCCESS, payload });
    },
    eStudySaga.FETCH_STUDY,
    eStudySaga.WATCH_STUDY
  );

  // override fetch of courses for testing, just return the mock data
  overrideSaga(
    function* fetchStudyCourses(action: IAction) {
      yield put({
        type: eStudySaga.FETCH_STUDY_COURSES_SUCCESS,
        payload: mockCourseData
      });
    },
    eStudySaga.FETCH_STUDY_COURSES,
    eStudySaga.WATCH_STUDY_COURSES
  );

  overrideSaga(
    function* fetchCourseThresholds(action: IAction) {
      yield put({
        type: eStudySaga.FETCH_COURSE_THRESHOLDS_SUCCESS,
        // return the appropriate mock data based on the courseId given
        payload: mockCourseThresholds[parseInt(action.payload, 10) - 1]
      });
    },
    eStudySaga.FETCH_COURSE_THRESHOLDS,
    eStudySaga.WATCH_COURSE_THRESHOLDS
  );

  overrideSaga(
    function* fetchCourseThresholdsLegacy(action: IAction) {
      yield put({
        type: eStudySaga.FETCH_COURSE_THRESHOLDS_LEGACY_SUCCESS,
        // return the appropriate mock data based on the courseId given
        payload: mockCourseThresholds[parseInt(action.payload, 10) - 1]
      });
    },
    eStudySaga.FETCH_COURSE_THRESHOLDS_LEGACY,
    eStudySaga.WATCH_COURSE_THRESHOLDS_LEGACY
  );

  // override save thresholds saga for testing.  Just return success.
  overrideSaga(
    function* saveCourseThresholds(action: IAction) {
      yield put({
        type: eStudySaga.SAVE_COURSE_THRESHOLDS_SUCCESS,
        payload: {}
      });
    },
    eStudySaga.SAVE_COURSE_THRESHOLDS,
    eStudySaga.WATCH_SAVE_COURSE_THRESHOLDS
  );

  // override fetch of study site personnel scoring course scores for testing
  overrideSaga(
    function* fetchPersonnelTrainingScores(action: IAction) {
      yield put({
        type: eStudySaga.FETCH_STUDY_PERSONNEL_TRAINING_SCORES_SUCCESS,
        payload: mockPersonnelTrainingScores
      });
    },
    eStudySaga.FETCH_STUDY_PERSONNEL_TRAINING_SCORES,
    eStudySaga.WATCH_STUDY_PERSONNEL_TRAINING_SCORES
  );

  overrideSaga(
    function* fetchCourseMasterQuestions(action: IAction) {
      yield put({
        type: eStudySaga.FETCH_COURSE_MASTER_QUESTIONS_SUCCESS,
        // return appropriate mock data for the course id passed in
        payload: mockMasterScoresData[parseInt(action.payload, 10) - 1]
      });
    },
    eStudySaga.FETCH_COURSE_MASTER_QUESTIONS,
    eStudySaga.WATCH_FETCH_COURSE_MASTER_QUESTIONS
  );

  overrideSaga(
    function* fetchCourseCountryQuestions(action: IAction) {
      yield put({
        type: eStudySaga.FETCH_COURSE_COUNTRY_QUESTIONS_SUCCESS,
        payload: mockCountryScoresData[4]
      });
    },
    eStudySaga.FETCH_COURSE_COUNTRY_QUESTIONS,
    eStudySaga.WATCH_FETCH_COURSE_COUNTRY_QUESTIONS
  );

  overrideSaga(
    function* updateCourseMasterQuestions(action: IAction) {
      yield put({
        type: eStudySaga.SAVE_COURSE_MASTER_QUESTIONS_SUCCESS,
        payload: {}
      });
    },
    eStudySaga.SAVE_COURSE_MASTER_QUESTIONS,
    eStudySaga.WATCH_SAVE_COURSE_MASTER_QUESTIONS
  );

  overrideSaga(
    function* lockCourseMasterQuestions(action: IAction) {
      yield put({
        type: eStudySaga.LOCK_COURSE_MASTER_QUESTIONS_SUCCESS,
        payload: {}
      });
    },
    eStudySaga.LOCK_COURSE_MASTER_QUESTIONS,
    eStudySaga.WATCH_LOCK_COURSE_MASTER_QUESTIONS
  );

  overrideSaga(
    function* fetchScoringCourseDetails(action: IAction) {
      yield put({
        type: eStudySaga.FETCH_SCORING_COURSE_DETAILS_SUCCESS,
        // return the appropriate mock data based on the courseId given
        payload: mockCountryScores[parseInt(action.payload.courseId, 10) - 1]
      });
    },
    eStudySaga.FETCH_SCORING_COURSE_DETAILS,
    eStudySaga.WATCH_SCORING_COURSE_DETAILS
  );
  overrideSaga(
    function* fetchScoringCourseDetailsLegacy(action: IAction) {
      yield put({
        type: eStudySaga.FETCH_SCORING_COURSE_DETAILS_LEGACY_SUCCESS,
        // return the appropriate mock data based on the courseId given
        payload: mockCountryScores[parseInt(action.payload.courseId, 10) - 1]
      });
    },
    eStudySaga.FETCH_SCORING_COURSE_DETAILS_LEGACY,
    eStudySaga.WATCH_SCORING_COURSE_DETAILS_LEGACY
  );

  overrideSaga(
    function* dispatchStudyQualifications(action: IAction) {
      if (action.payload) {
        yield put({
          type: eStudySaga.FETCH_STUDY_QUALIFICATIONS_SUCCESS,
          payload: [
            Object.assign(new StudyQualification(), {
              studyQualificationId: "1",
              studyId: "1",
              scaleId: "1",
              scaleName: "TestScale1",
              qualificationName: "TestQualification1",
              isActive: true,
              canDelete: true
            }),
            Object.assign(new StudyQualification(), {
              studyQualificationId: "2",
              studyId: "2",
              scaleId: "2",
              scaleName: "TestScale2",
              qualificationName: "TestQualification2",
              isActive: false,
              canDelete: true
            }),
            Object.assign(new StudyQualification(), {
              studyQualificationId: "3",
              studyId: "3",
              scaleId: "3",
              scaleName: "TestScale3",
              qualificationName: "TestQualification3",
              isActive: true,
              canDelete: true
            }),
            Object.assign(new StudyQualification(), {
              studyQualificationId: "4",
              studyId: "4",
              scaleId: null,
              scaleName: null,
              qualificationName: "TestQualification4",
              isActive: true,
              canDelete: false
            })
          ]
        });
      }
    },
    eStudySaga.FETCH_STUDY_QUALIFICATIONS,
    eStudySaga.WATCH_STUDY_QUALIFICATIONS
  );

  overrideSaga(
    function* dispatchStudyScales(action: IAction) {
      if (action.payload) {
        yield put({
          type: eStudySaga.FETCH_STUDY_SCALES_SUCCESS,
          payload: [
            Object.assign(new StudyScale(), {
              id: "1",
              studyId: "1",
              name: "TestScale2",
              shortName: "TS2",
              isActive: true
            }),
            Object.assign(new StudyScale(), {
              id: "2",
              studyId: "1",
              name: "TestScale1",
              shortName: "TS1",
              isActive: false
            })
          ]
        });
      }
    },
    eStudySaga.FETCH_STUDY_SCALES,
    eStudySaga.WATCH_STUDY_SCALES
  );

  overrideSaga(
    function* saveStudyScale(action: IAction) {
      if (action.payload) {
        yield put({ type: eStudySaga.SAVE_STUDY_SCALE_SUCCESS, payload: true });
      }
    },
    eStudySaga.SAVE_STUDY_SCALE,
    eStudySaga.WATCH_STUDY_SCALE
  );

  overrideSaga(
    function* deleteStudyScale(action: IAction) {
      if (action.payload) {
        yield put({
          type: eStudySaga.DELETE_STUDY_SCALE_SUCCESS,
          payload: true
        });
      }
    },
    eStudySaga.DELETE_STUDY_SCALE,
    eStudySaga.WATCH_DELETE_STUDY_SCALE
  );

  overrideSaga(
    function* dispatchStudyProtocols(action: IAction) {
      if (action.payload) {
        yield put({
          type: eStudySaga.FETCH_STUDY_PROTOCOLS_SUCCESS,
          payload: [
            Object.assign(new StudyProtocol(), {
              protocolId: "2",
              studyId: "1",
              programId: "2",
              protocolName: "TestProtocol2",
              programName: "TestProgram2"
            }),
            Object.assign(new StudyProtocol(), {
              protocolId: "1",
              studyId: "1",
              programId: "1",
              protocolName: "TestProtocol1",
              programName: "TestProgram1"
            })
          ]
        });
      }
    },
    eStudySaga.FETCH_STUDY_PROTOCOLS,
    eStudySaga.WATCH_STUDY_PROTOCOLS
  );

  overrideSaga(
    function* saveStudyProtocol(action: IAction) {
      if (action.payload) {
        yield put({
          type: eStudySaga.MODIFY_STUDY_PROTOCOL_SUCCESS,
          payload: true
        });
      }
    },
    eStudySaga.MODIFY_STUDY_PROTOCOL,
    eStudySaga.WATCH_STUDY_PROTOCOL_SAVE
  );

  // override for testing.  Return mock data to use for unit test.
  overrideSaga(
    function* fetchStudySiteSaga(action: IAction) {
      if (action.payload) {
        yield put({
          type: eStudySaga.FETCH_STUDY_SITE_SUCCESS,
          payload: {
            siteId: "1",
            studyId: "1",
            sponsorSiteId: "Sponsor Site ID",
            primaryInvestigator: {
              FirstName: "Ed",
              LastName: "Martini",
              key: { UserName: "ed.martini", type: "Internal" }
            },
            primaryInvestigatorHistory: [],
            isActive: true,
            Protocols: [{ id: "1", protocolName: "TestProtocol1" }]
          }
        });
      }
    },
    eStudySaga.FETCH_STUDY_SITE,
    eStudySaga.WATCH_STUDY_SITE
  );

  overrideSaga(
    function* dispatchStudySites(action: IAction) {
      if (action.payload) {
        yield put({
          type: eStudySaga.FETCH_STUDY_SITES_SUCCESS,
          payload: [
            Object.assign(new StudySite(), {
              studySiteId: "1",
              studyId: "1",
              sponsorSiteId: "1",
              siteName: "TestSite1",
              siteMonitorId: "1",
              siteMonitor: "TestMonitor1",
              siteId: "1",
              primaryInvestigator: {
                FirstName: "Ed",
                LastName: "Martini",
                key: { UserName: "ed.martini", type: "Internal" }
              },
              isActive: true,
              countryValue: "US",
              country: "United States"
            }),
            Object.assign(new StudySite(), {
              studySiteId: "1",
              studyId: "1",
              sponsorSiteId: "1",
              siteName: "TestSite1",
              siteMonitorId: "1",
              siteMonitor: "TestMonitor1",
              siteId: "1",
              primaryInvestigator: {
                FirstName: "Ed",
                LastName: "Martini",
                key: { UserName: "ed.martini", type: "Internal" }
              },
              isActive: true,
              countryValue: "US",
              country: "United States"
            }),
            Object.assign(new StudySite(), {
              studySiteId: "2",
              studyId: "1",
              sponsorSiteId: "2",
              siteName: "TestSite2",
              siteMonitorId: "2",
              siteMonitor: "TestMonitor2",
              siteId: "2",
              primaryInvestigator: {
                FirstName: "Edward",
                LastName: "Martini",
                key: { UserName: "edward.martini", type: "External" }
              },
              isActive: false,
              countryValue: "CA",
              country: "Canada"
            })
          ]
        });
      }
    },
    eStudySaga.FETCH_STUDY_SITES,
    eStudySaga.WATCH_STUDY_SITES
  );

  overrideSaga(
    function* fetchStudySitePerson(action: IAction) {
      const persons = [
        {},
        {
          personnelId: "1",
          key: { userName: "User Number1", type: "Internal" },
          firstName: "User",
          lastName: "Number1",
          email: "user1@email.com",
          roles: [
            {
              roleId: "de88796f-f83d-ea11-8c6e-00155d0f1e6b",
              name: "Rater"
            }
          ],
          protocols: [],
          participatingSites: [],
          studySitePersonnelContactInfo: {
            phone: "123-456-7890",
            mobilePhone: "999-212-9999"
          },
          isActive: true
        },
        {
          personnelId: "2",
          key: { userName: "User Number2", type: "Internal" },
          firstName: "User",
          lastName: "Number2",
          email: "user2@email.com",
          roles: [
            {
              roleId: "df88796f-f83d-ea11-8c6e-00155d0f1e6b",
              name: "Principal Investigator"
            }
          ],
          protocols: [],
          participatingSites: [],
          studySitePersonnelContactInfo: {
            phone: "888-532-4224",
            mobilePhone: "712-513-8363"
          },
          isActive: true
        },
        {
          personnelId: "3",
          key: { userName: "User Number3", type: "Internal" },
          firstName: "User",
          lastName: "Number3",
          email: "user3@email.com",
          roles: [
            {
              roleId: "de88796f-f83d-ea11-8c6e-00155d0f1e6b",
              name: "Rater"
            }
          ],
          protocols: [{ id: "1" }],
          participatingSites: [
            {
              siteId: "1111-2222-3333-4444",
              siteName: "General Hospital",
              isActive: true
            },
            {
              siteId: "5555-6666-7777-8888",
              siteName: "West Podunk Clinic",
              isActive: false
            }
          ],
          studySitePersonnelContactInfo: {
            phone: "",
            mobilePhone: "712-513-8363"
          },
          isActive: false
        }
      ];
      if (action.payload) {
        const { PersonnelId } = action.payload.dto;
        yield put({
          type: eStudySaga.FETCH_STUDY_SITE_PERSON_SUCCESS,
          payload: persons[parseInt(PersonnelId, 10)]
        });
      }
    },
    eStudySaga.FETCH_STUDY_SITE_PERSON,
    eStudySaga.WATCH_STUDY_SITE_PERSON
  );

  overrideSaga(
    function* (action: IAction) {
      yield put({
        type: eStudySaga.FETCH_PERSONNEL_IN_SITE_SUCCESS,
        payload: mockPersonnelAtSite
      });
    },
    eStudySaga.FETCH_PERSONNEL_IN_SITE,
    eStudySaga.WATCH_PERSONNEL_IN_SITE
  );

  // Overridge the fetch study saga for testing.  Bypasses the API call when the
  // saga is dispatched and returns the dummy payload.
  overrideSaga(
    function* fetchStudyQualification(action: IAction) {
      if (action.payload) {
        yield put({
          type: eStudySaga.FETCH_STUDY_QUALIFICATION_SUCCESS,
          payload: {
            scaleId: "1",
            scaleName: "TestScale1",
            qualificationName: "TestQualification1",
            learningPlans: [],
            legacyLearningPlans:
              action.payload.studyId === "2"
                ? [{ id: "abcd-1234", name: "Test Legacy LP" }]
                : [], // return legacy info if study id of 2 is passed in by the test
            isActive: true,
            qualificationType: "TRAINING",
            isExperienceRequired: false
          }
        });
      }
    },
    eStudySaga.FETCH_STUDY_QUALIFICATION,
    eStudySaga.WATCH_STUDY_QUALIFICATION
  );

  // Override update of comment (editing comment) for testing.
  // Just return success.
  overrideSaga(
    function* updateStudySitePersonComment(action: IAction) {
      if (action.payload) {
        yield put({
          type: eStudySaga.UPDATE_STUDY_SITE_PERSON_COMMENT_SUCCESS,
          payload: {
            studySitePersonnelCommentId: "123",
            commentText: "New comment"
          }
        });
      }
    },
    eStudySaga.UPDATE_STUDY_SITE_PERSON_COMMENT,
    eStudySaga.WATCH_UPDATE_STUDY_SITE_PERSON_COMMENT
  );

  overrideSaga(
    function* fetchStudySitePersonComments(action: IAction) {
      const comments: IComment[] = [
        {
          commentDate: "2020-06-26T12:49:29.332481+00:00",
          commentText: "Test Comment",
          commentType: { value: "GENERAL", label: "General" },
          commentUpdatedDate: "2020-06-26T12:49:29.332481+00:00",
          studySitePersonnelCommentId: "123",
          userName: "john.doe@signanthealth.com",
          isEditable: false
        },
        {
          commentDate: "2020-06-27T12:49:29.332481+00:00",
          commentText: "Test Comment #2",
          commentType: { value: "GENERAL", label: "General" },
          commentUpdatedDate: "2020-06-27T12:49:29.332481+00:00",
          studySitePersonnelCommentId: "124",
          userName: "bruce.springsteen@signanthealth.com",
          isEditable: true
        },
        {
          commentDate: "2020-06-28T12:49:29.332481+00:00",
          commentText: "Test Comment #3",
          commentType: { value: "GENERAL", label: "General" },
          commentUpdatedDate: "2020-06-29T12:49:29.332481+00:00",
          studySitePersonnelCommentId: "125",
          userName: "billy.joel@signanthealth.com",
          isEditable: false
        }
      ];
      yield put({
        type: eStudySaga.FETCH_STUDY_SITE_PERSON_COMMENTS_SUCCESS,
        payload: comments
      });
    },
    eStudySaga.FETCH_STUDY_SITE_PERSON_COMMENTS,
    eStudySaga.WATCH_FETCH_STUDY_SITE_PERSON_COMMENTS
  );

  // Override saving a new study site person comment - just return success
  overrideSaga(
    function* saveStudySitePersonComment(action: IAction) {
      yield put({
        type: eStudySaga.SAVE_STUDY_SITE_PERSON_COMMENT_SUCCESS,
        payload: {
          studyId: "1",
          personnelId: "1",
          commentText: "My test comment",
          userName: "john.doe@signanthealth.com",
          commentType: { value: "GENERAL", label: "General" }
        }
      });
    },
    eStudySaga.SAVE_STUDY_SITE_PERSON_COMMENT,
    eStudySaga.WATCH_SAVE_STUDY_SITE_PERSON_COMMENT
  );

  // Overridge the fetch experience saga for testing.  Bypasses the API call when the
  // saga is dispatched and returns the dummy payload.
  overrideSaga(
    function* fetchStudySitePersonExperience(action: IAction) {
      if (action.payload) {
        yield put({
          type: eStudySaga.FETCH_STUDY_SITE_PERSON_EXPERIENCE_SUCCESS,
          payload: [
            {
              courseId: 141,
              courseName: "Education Experience",
              experienceTag: "General-Experience",
              questions: [
                { description: "Degree(s)", response: ["B.A", "B.S", "M.D."] },
                {
                  description: "Specialty(s)",
                  response: ["Neurology", "Other"]
                },
                {
                  description: "General Clinical Experience",
                  response: ["10 years 3 months"]
                },
                {
                  description: "General Clinical Trial Experience",
                  response: ["6 years 9 months"]
                }
              ]
            },
            {
              courseId: 142,
              courseName: "Clinical Experience",
              experienceTag: "Clinical-Experience",
              questions: [
                { description: "Indication1", response: ["Dementia"] },
                { description: "Response1", response: ["9 years 3 months"] },
                { description: "Demographic1", response: ["Adult"] },
                { description: "Response2", response: ["6 years 9 months"] }
              ]
            },
            {
              courseId: 145,
              courseName: "Clinical Trial Experience",
              experienceTag: "Clinical-Experience",
              questions: [
                { description: "Indication2", response: ["Bipolar"] },
                { description: "Response3", response: ["11 years 6 months"] },
                { description: "Demographic2", response: ["Adult"] },
                { description: "Response4", response: ["8 years 2 months"] }
              ]
            },
            {
              courseId: 144,
              courseName: "Additional Questions",
              experienceTag: "Additional-Questions",
              questions: [
                {
                  description:
                    "How many years experience do you have with this study",
                  response: ["15 years 1 month"]
                },
                {
                  description: "Did you participate with the 100 protocol",
                  response: ["No"]
                }
              ]
            }
          ]
        });
      }
    },
    eStudySaga.FETCH_STUDY_SITE_PERSON_EXPERIENCE,
    eStudySaga.WATCH_STUDY_SITE_PERSON_EXPERIENCE
  );

  // Overridge the fetch scale experience saga for testing.  Bypasses the API call when the
  // saga is dispatched and returns the dummy payload.
  overrideSaga(
    function* fetchStudySitePersonScaleExperience(action: IAction) {
      if (action.payload) {
        yield put({
          type: eStudySaga.FETCH_STUDY_SITE_PERSON_SCALE_EXPERIENCE_SUCCESS,
          payload: mockScaleExperienceData
        });
      }
    },
    eStudySaga.FETCH_STUDY_SITE_PERSON_SCALE_EXPERIENCE,
    eStudySaga.WATCH_STUDY_SITE_PERSON_SCALE_EXPERIENCE
  );

  overrideSaga(
    function* fetchScaleExperienceNextAvailableStatuses(action: IAction) {
      if (action.payload) {
        yield put({
          type: eStudySaga.FETCH_SCALE_EXPERIENCE_NEXT_AVAILABLE_STATUSES_SUCCESS,
          payload: mockScaleExperienceData
        });
      }
    },
    eStudySaga.FETCH_SCALE_EXPERIENCE_NEXT_AVAILABLE_STATUSES,
    eStudySaga.WATCH_SCALE_EXPERIENCE_NEXT_AVAILABLE_STATUSES
  );

  // Overridge the fetch training saga for testing.  Bypasses the API call when the
  // saga is dispatched and returns the dummy payload.
  overrideSaga(
    function* fetchStudySitePersonTraining(action: IAction) {
      if (action.payload) {
        yield put({
          type: eStudySaga.FETCH_STUDY_SITE_PERSON_TRAINING_SUCCESS,
          payload: { userStudyQualificationStatuses: mockTrainingData }
        });
      }
    },
    eStudySaga.FETCH_STUDY_SITE_PERSON_TRAINING,
    eStudySaga.WATCH_STUDY_SITE_PERSON_TRAINING
  );

  // Overridge the fetch studies saga (search) for testing.  Bypasses the API call when the
  // saga is dispatched and returns the dummy payload.
  overrideSaga(
    function* fetchStudies(action: IAction) {
      const result = mockStudyData.map((st: any) =>
        Object.assign(new Study(), st)
      );
      if (action.payload) {
        yield put({
          type: eStudySaga.FETCH_STUDIES_SUCCESS,
          payload: result
        });
      }
    },
    eStudySaga.FETCH_STUDIES,
    eStudySaga.WATCH_STUDIES
  );

  // Override assignment of LP for testing.
  // Just return success.
  overrideSaga(
    function* saveStudySitePersonLPAssignment(action: IAction) {
      if (action.payload) {
        yield put({
          type: eStudySaga.SAVE_STUDY_SITE_PERSON_LP_ASSIGNMENT_SUCCESS,
          payload: {}
        });
      }
    },
    eStudySaga.SAVE_STUDY_SITE_PERSON_LP_ASSIGNMENT,
    eStudySaga.WATCH_SAVE_STUDY_SITE_PERSON_LP_ASSIGNMENT
  );

  // Override unassigning a LP.  Just return success.
  overrideSaga(
    function* unassignLP(action: IAction) {
      yield put({
        type: eStudySaga.UNASSIGN_LP_SUCCESS,
        payload: {}
      });
    },
    eStudySaga.UNASSIGN_LP,
    eStudySaga.WATCH_UNASSIGN_LP
  );

  // Override save of course status.  Return a success with
  // some dummy data.
  overrideSaga(
    function* saveCourseStatus(action: IAction) {
      yield put({
        type: eStudySaga.SAVE_COURSE_STATUS_SUCCESS,
        payload: {
          courseId:
            action.payload.status === EXPERIENCE_STATUS_VALUE.EXEMPT
              ? 142
              : 160,
          courseStatusDate: "2020-07-09T18:50:13.0961696+00:00",
          courseStatus:
            action.payload.status === EXPERIENCE_STATUS_VALUE.EXEMPT
              ? {
                  name: EXPERIENCE_STATUS_DISPLAY_VALUE.EXEMPT,
                  value: EXPERIENCE_STATUS_VALUE.EXEMPT
                }
              : {
                  name: EXPERIENCE_STATUS_DISPLAY_VALUE.INCOMPLETE,
                  value: EXPERIENCE_STATUS_VALUE.INCOMPLETE
                },
          learningPlanStatuses: [],
          studyQualificationStatuses: []
        }
      });
    },
    eStudySaga.SAVE_COURSE_STATUS,
    eStudySaga.WATCH_SAVE_COURSE_STATUS
  );

  // Override fetch of bulk LP data for testing.  Just return the mock data.
  overrideSaga(
    function* fetchBulkLPData(action: IAction) {
      yield put({
        type: eStudySaga.FETCH_BULK_LP_DATA_SUCCESS,
        payload: mockBulkLPAssignData
      });
    },
    eStudySaga.FETCH_BULK_LP_DATA,
    eStudySaga.WATCH_BULK_LP_DATA
  );

  // Override fetch of LPs that are valid for bulk assignment.  Return the mock data.
  overrideSaga(
    function* fetchLearningPlansForBulkAssign(action: IAction) {
      yield put({
        type: eStudySaga.FETCH_LEARNING_PLANS_BULK_ASSIGN_SUCCESS,
        payload: mockBulkLPs
      });
    },
    eStudySaga.FETCH_LEARNING_PLANS_BULK_ASSIGN,
    eStudySaga.WATCH_FETCH_LEARNING_PLANS_BULK_ASSIGN
  );

  // Override save of bulk LPs - just return success
  overrideSaga(
    function* saveBulkLPAssignment(action: IAction) {
      yield put({
        type: eStudySaga.SAVE_BULK_LP_ASSIGNMENT_SUCCESS,
        payload: {}
      });
    },
    eStudySaga.SAVE_BULK_LP_ASSIGNMENT,
    eStudySaga.WATCH_BULK_LP_ASSIGNMENT
  );

  // Override fetch of training course manual scores for testing
  overrideSaga(
    function* getTrainingCourseManualScores(action: IAction) {
      yield put({
        type: eStudySaga.FETCH_TRAINING_COURSE_MANUAL_SCORES_SUCCESS,
        payload: mockTrainingCourseScores
      });
    },
    eStudySaga.FETCH_TRAINING_COURSE_MANUAL_SCORES,
    eStudySaga.WATCH_FETCH_TRAINING_COURSE_MANUAL_SCORES
  );

  // Override save of training course manual scores for testing - just return success
  overrideSaga(
    function* saveTrainingCourseManualScores(action: IAction) {
      yield put({
        type: eStudySaga.SAVE_TRAINING_COURSE_MANUAL_SCORES_SUCCESS,
        payload: {}
      });
    },
    eStudySaga.SAVE_TRAINING_COURSE_MANUAL_SCORES,
    eStudySaga.WATCH_SAVE_TRAINING_COURSE_MANUAL_SCORES
  );

  // Override delete of training course manual scores for testing - just return success
  overrideSaga(
    function* deleteTrainingCourseManualScores(action: IAction) {
      yield put({
        type: eStudySaga.DELETE_TRAINING_COURSE_MANUAL_SCORES_SUCCESS,
        payload: {}
      });
    },
    eStudySaga.DELETE_TRAINING_COURSE_MANUAL_SCORES,
    eStudySaga.WATCH_DELETE_TRAINING_COURSE_MANUAL_SCORES
  );

  overrideSaga(
    function* saveClinicalData(action: IAction) {
      yield put({
        type: eStudySaga.SAVE_CLINICAL_DATA_STUDY_SUCCESS,
        payload: {}
      });
      yield store.dispatch(openSuccessSnackBar("Save successful"));
    },
    eStudySaga.SAVE_CLINICAL_DATA_STUDY,
    eStudySaga.WATCH_SAVE_CLINICAL_DATA_STUDY
  );
};
